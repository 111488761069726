import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"

import { deleteFoodGroup } from '../../redux/actions/foodGroup'
import { updateScheduleBag } from '../../redux/actions/schedule'
import { getMe, updateUser } from '../../redux/actions/user'

import EditFoodGroup from "../EditFoodGroup"

import CheckIcon from '../../assets/icons/check.png'
import { TiPencil } from "react-icons/ti"
import { IoCloseSharp } from "react-icons/io5"
import { FaRegTrashAlt } from "react-icons/fa"

import './styles.css'

const FoodGroupItem = ({ 
    ownerId, 
    category, 
    cost, 
    fmv, 
    items, 
    taxSaving, 
    itemId,
    donationId,
    scheduledDonation,
    disptachGetMe, 
    dispatchUpdateUser, 
    dispatchDeleteFoodGroup,
    dispatchUpdateScheduleBag,
    getme 
}) => {
    const [edit, setEdit] = useState(false)
    const [editGroup, setEditGroup] = useState(false)
    const [editFmv, setEditFmv] = useState('')
    const [lbs, setLbs] = useState('')
    const [deleteItem, setDeleteItem] = useState(false)
    const [open, setOpen] = useState(false)
    const [fullCart, setFullCart] = useState(false)
    let menuRef = useRef()


    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    // const bag = getme.bag.items.find(item => item.itemId === itemId)

    // const currenLbs = !bag?.lbs ? 0 : bag?.lbs
    // const checkBagLbs = (getme.bag.totals.totalWeight - currenLbs) + Number(lbs)

    // const updateBag = (action) => {
    //     if (checkBagLbs > 300 && action === 'add') {
    //         setFullCart(true)
    //     } else {
    //         const item = {
    //             itemId: itemId,
    //             category: category,
    //             items: items,
    //             cost: cost,
    //             fmv: fmv,
    //             taxSaving: taxSaving,
    //             lbs: Number(lbs),
    //         }
    //         dispatchUpdateUser(
    //             action,
    //             item,
    //             getme.id,
    //             () => { disptachGetMe(); setEdit(false) },
    //             (error) => console.log(error)
    //         )
    //     }
    // }

    const deleteFoddItem = () => {
        dispatchDeleteFoodGroup(
            itemId,
            () => { disptachGetMe(); setDeleteItem(false) },
            (error) => console.log(error)
        )
    }

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef?.current?.contains(event.target)) {
                setOpen(false)
                setEdit(false)
                setEditGroup(false)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    let findBag
    let bag
    let currentLbs
    let checkBagLbs
    if (scheduledDonation) {
        findBag = getme.donations.find(donation => donation.id === donationId).bag
        bag = findBag.items.find(item => item.itemId === itemId)
        currentLbs  = !findBag?.lbs ? 0 : findBag?.lbs
        checkBagLbs = (findBag.totals.totalWeight - currentLbs) + Number(lbs)
    } else {
        bag = getme.bag.items.find(item => item.itemId === itemId)
        currentLbs  = !bag?.lbs ? 0 : bag?.lbs
        checkBagLbs = (getme.bag.totals.totalWeight - currentLbs) + Number(lbs)
    }

    const updateBag = (action) => {
        if (action === 'add' || 'remove') {
            if (checkBagLbs > 300 && action === 'add') {
                alert(
                    'Donation must be 300lbs or less',
                    'If your donation is bigger, please split into a second delivery.'
                )
            } else {
                const item = {
                    itemId: itemId,
                    category: category,
                    cost: cost,
                    fmv: fmv,
                    taxSaving: taxSaving,
                    lbs: Number(lbs),
                }
                if (scheduledDonation)  {
                    dispatchUpdateScheduleBag(
                        action, 
                        item, 
                        findBag, 
                        donationId, 
                        () => disptachGetMe(), 
                        (error) => console.log(error)
                    );
                    setEdit(false)
                } else {
                    dispatchUpdateUser(
                        action,
                        item,
                        getme.id,
                        () => { disptachGetMe(); setEdit(false) },
                        (error) => console.log(error)
                    )
                }
            }
        } else if (action === 'update') {
            console.log('update')
        }
    }

    const lbFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(Math.round(Number(taxSaving) * 100) / 100)

    return (
        <>
            {deleteItem && (
                <div className='bag_item_modal'>
                    <div className='bag_item_modal_container'>
                        <IoCloseSharp size={35} className='subscribe_modal_icon_close' onClick={() => setDeleteItem(false)} />
                        <p className='subscribe_modal_title'>Are you sure?</p>
                        <p className='bag_item_modal_subtitle'>Do you really want to delete the group: <br /> {category}</p>
                        <>
                            <button className='bag_modal_button' onClick={deleteFoddItem}>Delete</button>
                            <button onClick={() => setDeleteItem(false)} className='checkout_estimate_modal_button_retry'>Cancel</button>
                        </>
                    </div>
                </div>
            )}

            {fullCart && (
                <div className='bag_item_modal'>
                    <div className='bag_item_modal_container'>
                        <IoCloseSharp size={35} className='checkout_estimate_modal_icon' onClick={() => setFullCart(false)} />
                        <p className='checkout_estimate_modal_title'>Limit Reached</p>
                        <p className='bag_item_modal_subtitle'>Donation must be under 300 pounds and fit in the backseat of a sedan.</p>
                        <p className='bag_item_modal_subtitle'>Please reduce the weight. You can create another donation after you complete <br /> this one!</p>
                        <Link className='bag_modal_button' to="/checkout">Conclude Donation</Link>
                    </div>
                </div>
            )}

            <div className='bag_item' ref={menuRef}>
                {editGroup && (<EditFoodGroup
                    setEditGroup={setEditGroup}
                    category={category}
                    items={items}
                    itemId={itemId}
                    ownerId={ownerId}
                />)}

                {edit && open ?
                    <div className={bag?.itemId === itemId ? 'bag_item_edit_added' : 'bag_item_edit'}>
                        <IoCloseSharp className='bag_item_icon_close' color="FFF" size={15} onClick={() => {
                            {
                                setOpen(false);
                                setEdit(false);
                                setEditGroup(false);
                            }
                        }} />
                        <p className='bag_item_title'>{category}</p>
                        <>
                            <div className='bag_item_edit_add'>
                                <input className='bag_item_edit_add_input'
                                    value={lbs}
                                    placeholder={bag?.lbs}
                                    type='number'
                                    onChange={(e) => setLbs(e.target.value)}
                                />
                                <p className='bag_item_subtitle'>lbs</p>
                                <img src={CheckIcon} alt='check' className='bag_item_edit_add_check_icon' onClick={() => updateBag('add')} />
                            </div>
                            {bag?.itemId === itemId && (
                                <FaRegTrashAlt color="#FFF" size={25} style={{ cursor: 'pointer' }} onClick={() => { updateBag('remove') }} />
                            )}
                            {bag?.itemId !== itemId && (
                                <>
                                    <div className='bag_item_edit_section'>
                                        <p className='bag_item_lb_text'>{lbFormat}/LB</p>
                                        <TiPencil color='#FFF' size={19} style={{ cursor: 'pointer' }} onClick={() => { setEdit(false); setEditGroup(true) }} />
                                    </div>
                                    <button className='bag_item_edit_price_button' onClick={() => setDeleteItem(true)} >Remove</button>
                                </>
                            )}
                        </>
                    </div>
                    : bag?.itemId !== itemId ?
                        <div className='bag_item_empty' onClick={() => { setEdit(true); setOpen(true) }}>
                            <p className='bag_item_title'>{category}</p>
                            <p className='bag_item_title'>{lbFormat}/LB</p>
                        </div>
                        :
                        <div className='bag_item_filled' onClick={() => { setEdit(true); setOpen(true) }}>
                            <p className='bag_item_title'>{category}</p>
                            <p className='bag_item_title'>{bag?.lbs} LBS</p>
                            <img src={CheckIcon} alt='check' className='bag_item_edit_add_check_icon' />
                        </div>
                        
                }
            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateUser: (action, foodItem, userId, onSuccess, onError) =>
        dispatch(updateUser({ action, foodItem }, userId, onSuccess, onError)),
    dispatchDeleteFoodGroup: (itemId, onSuccess, onError) =>
        dispatch(deleteFoodGroup(itemId, onSuccess, onError)),
    dispatchUpdateScheduleBag: (action, foodItem, currentBag, donationId, onSuccess, onError) =>
        dispatch(updateScheduleBag({ action, foodItem, currentBag, donationId }, onSuccess, onError)),
    disptachGetMe: () => dispatch(getMe()),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(FoodGroupItem)