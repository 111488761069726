import React from "react"
import { createRoot } from 'react-dom/client'
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { BrowserRouter } from "react-router-dom"
import AppRoutes from "./routes"
import { store, persistor } from "./redux/store"
import "./global.css"
const root = createRoot(document.getElementById("root"))

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
)