import { combineReducers } from 'redux'

import user from './user'
import getme from './getme'
import recipients from './recipients'

const rootReducer = combineReducers({
    user,
    getme,
    recipients
})

export default rootReducer