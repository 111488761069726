import React from "react"
import { Link } from "react-router-dom"

import './styles.css'

const CustomButton = ({isLink, to, state, onClick, title, color, style}) => {

    return (
        <>
            {isLink ?
                <Link to={to} state={state} className={`custom_button_${color}`} style={style} onClick={onClick}>
                    {title}
                </Link>
                :
                <button onClick={onClick} className={`custom_button_${color}`} style={style}>
                    {title}
                </button>
            }
        </>
    )
}

export default CustomButton