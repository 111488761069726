import * as constants from '../constants'

export const createFoodItem = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/food-item',
        data,
        success: (response) => createdFoodItem(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const updateFoodItem = (data, itemId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'PATCH',
        url: `/food-item/${itemId}`,
        data,
        success: (response) => updatedFoodItem(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const deleteFoodItem = (itemId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: `/food-item/${itemId}`,
        success: (response) => deletedFoodItem(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

const createdFoodItem = (data) => ({
    type: constants.CREATE_FOOD_ITEM,
    payload: data,
})

const updatedFoodItem = (data) => ({
    type: constants.UPDATE_FOOD_ITEM,
    payload: data,
})

const deletedFoodItem = (data) => ({
    type: constants.DELETE_FOOD_ITEM,
    payload: data,
})