import * as constants from '../constants'

export const createReportOne = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/report/one',
        data,        
        success: (response) => createdeportOne(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

const createdeportOne = (data) => ({
    type: constants.CREATE_REPORT_ONE,
    payload: data,
})

export const createReportTwo = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/report/two',
        data,        
        success: (response) => createdeportTwo(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

const createdeportTwo = (data) => ({
    type: constants.CREATE_REPORT_TWO,
    payload: data,
})