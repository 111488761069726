import React, { useState } from 'react'
import moment from 'moment'
import saveAs from 'file-saver'
import ModalEditDonation from '../ModalEditDonation'
import PlusIcon from '../../assets/icons/plus.svg'
import MinusIcon from '../../assets/icons/minus.svg'
import EditIcon from '../../assets/icons/edit.svg'

import './styles.css'

const HistoryCard = ({ createdAt, bag, deliveryEstimate, receiptUrl, id, status }) => {
    const [selected, setSelected] = useState(false)
    const [editModal, setEditModal] = useState(false)
    const [item, setItem] = useState({})

    const taxSavingTotalFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(bag.totals.totalTaxSavings)
    const lbsFormat = new Intl.NumberFormat('us').format(bag.totals.totalWeight)

    function handleDownload(name) {
        saveAs(receiptUrl, `receipt_${id}.pdf`)
    }

    function editDonation(item) {
        setEditModal(true)
        setItem(item)
    }

    const parsedDate = createdAt.slice(0, 10).replace(/,/g, "").trim()

    return (
        <div className='history_card'>
            {editModal && <ModalEditDonation item={item} items={bag.items} donationId={id} setEditModal={setEditModal} />}
            <div className='history_card_header'>
                <div className='history_card_date_container'>
                    <p className='history_card_text_date'>{moment(parsedDate).format('MMMM DD, YYYY')}</p>
                </div>
                <div className='history_card_total_container'>
                    <div className='history_card_text_total_container'>
                        <p className='history_card_text_total'>{taxSavingTotalFormat}</p>
                        <p className='history_card_text_lbs'>{lbsFormat} lbs</p>
                    </div>
                    <div>
                        {!selected ?
                            <img src={PlusIcon} alt='add' className='history_card_add_icon' onClick={() => setSelected(!selected)} />
                            : <img src={MinusIcon} alt='add' className='history_card_add_icon' onClick={() => setSelected(!selected)} />
                        }
                    </div>
                </div>

            </div>
            {selected && (
                <div className='history_card_expanded_container'>
                    {bag.items.map((item) =>
                        <div className='history_card_expanded_donation_items'>
                            <p className='history_card_expanded_donation_title'>{item.itemType || item.category}</p>
                            <p className='history_card_expanded_donation_subtitle'>{new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(item.taxSaving)} / lb</p>
                            <div className='history_card_expanded_edit_container'>
                                <p className='history_card_expanded_donation_lbs_text'>{new Intl.NumberFormat('us').format(item.lbs)} lbs</p>
                                <img src={EditIcon} style={{ width: 20, height: 20, marginLeft: 15, cursor: 'pointer' }} onClick={() => editDonation(item)} />
                            </div>
                        </div>
                    )}

                    <div className='history_card_expanded_donation'>
                        {status !== 'closed' ? null :
                            <p className='history_card_expanded_donation_link' onClick={handleDownload}>Donation Receipt</p>
                        }
                        <div>
                            <p className='history_card_expanded_donation_lbs_text'>Total: <span style={{ marginLeft: 20 }}>{lbsFormat} lbs</span></p>
                            <p className='history_card_expanded_donation_delivery_text'>Delivery Fee: <span style={{ marginLeft: 20 }}>{new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(deliveryEstimate)}</span></p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default HistoryCard