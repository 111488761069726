import * as constants from '../constants'

export const createFoodGroup = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/food-group',
        data,
        success: (response) => createdFoodGroup(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const updateFoodGroup = (data, itemId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'PATCH',
        url: `/food-group/${itemId}`,
        data,
        success: (response) => updatedFoodGroup(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const deleteFoodGroup = (itemId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: `/food-group/${itemId}`,
        success: (response) => deletedFoodGroup(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

const createdFoodGroup = (data) => ({
    type: constants.CREATE_FOOD_GROUP,
    payload: data,
})

const updatedFoodGroup = (data) => ({
    type: constants.UPDATE_FOOD_GROUP,
    payload: data,
})

const deletedFoodGroup = (data) => ({
    type: constants.DELETE_FOOD_GROUP,
    payload: data,
})