import React, { useState, useEffect } from 'react'
import StripeSecure from '../../assets/images/stripe_secure.png'

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutForm from '../../components/CheckoutForm'
import { IoCloseSharp } from "react-icons/io5"
import { getMe } from '../../redux/actions/user'
import { connect } from "react-redux"
import Visa from '../../assets/images/visa.png'
import Mastercard from '../../assets/images/mastercard.png'
import Discover from '../../assets/images/discover.png'
import Amex from '../../assets/images/american-express.png'
import { createSubscription, createAnnualDiscountSubscription, cancelSubscription } from '../../redux/actions/payments'
import CustomButton from '../../components/CustomButton'
import LoadingButton from '../../components/LoadingButton'
import CardIcon from '../../assets/icons/card.svg'

import './styles.css'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    }
}

const promise = loadStripe(process.env.REACT_APP_STRIPE_PK)

const Billing = ({ disptachGetMe, getme, dispatchCreateSubscription, dispatchCreateAnnualDiscountSubscription, dispatchCancelSubscription }) => {
    const [addCard, setAddCard] = useState(false)
    const [loading, setIsLoading] = useState(false)
    const [subscribe, setSubscribe] = useState(false)
    const [cancel, setCancel] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    const handleCreateSubscription = (event) => {
        event.preventDefault()
        setIsLoading(true)
        dispatchCreateSubscription(
            getme.id,
            () => { disptachGetMe(); setIsLoading(false); setSubscribe(false); },
            (error) => { console.log(error); setIsLoading(false) }
        )
    }

    const handleCreateAnnualDiscountSubscription = (event) => {
        event.preventDefault()
        setIsLoading(true)
        dispatchCreateAnnualDiscountSubscription(
            getme.id,
            () => { disptachGetMe(); setIsLoading(false); setSubscribe(false); },
            (error) => { console.log(error); setIsLoading(false) }
        )
    }

    const handleCancelSubscription = (event) => {
        event.preventDefault()
        setIsLoading(true)
        dispatchCancelSubscription(
            getme.id,
            () => { disptachGetMe(); setIsLoading(false); setCancel(false); },
            (error) => { console.log(error); setIsLoading(false) }
        )
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <div className='billing'>
            {windowDimensions.width < 800 && (
                <div className='header_page_title_container'>
                    <img src={CardIcon} className='header_page_title_icon_card' />
                    <p className='header_page_title'>Billing</p>
                </div>
            )}
            {
                subscribe && !getme.billing.annualDiscount ? (
                    <div className='bag_item_modal'>
                        <div className='bag_item_modal_container'>
                            <IoCloseSharp size={35} className='subscribe_modal_icon_close' onClick={() => setSubscribe(false)} />
                            <p className='subscribe_modal_title'>Confirm Membership</p>
                            <p className='bag_item_modal_subtitle'>You payment method will be charged <br /> $45 per month.</p>
                            <>
                                <LoadingButton
                                    title='Join elePlate!'
                                    color='blue'
                                    onClick={handleCreateSubscription}
                                    style={{ marginTop: 30 }}
                                    isLoading={loading}
                                />
                            </>
                        </div>
                    </div>
                ) : subscribe && getme.billing.annualDiscount && (
                    <div className='bag_item_modal'>
                        <div className='bag_item_modal_container'>
                            <IoCloseSharp size={35} className='subscribe_modal_icon_close' onClick={() => setSubscribe(false)} />
                            <p className='subscribe_modal_title'>Confirm Membership</p>
                            <p className='bag_item_modal_subtitle'>You payment method will be charged <br /> $120 per year.</p>
                            <>
                                <LoadingButton
                                    title='Join elePlate!'
                                    color='blue'
                                    onClick={handleCreateAnnualDiscountSubscription}
                                    style={{ marginTop: 30 }}
                                    isLoading={loading}
                                />
                            </>
                        </div>
                    </div>
                )
            }
            {
                cancel && (
                    <div className='bag_item_modal'>
                        <div className='bag_item_modal_container'>
                            <IoCloseSharp size={35} className='subscribe_modal_icon_close' onClick={() => setCancel(false)} />
                            <p className='subscribe_modal_title'>Are you sure?</p>
                            <p className='bag_item_modal_subtitle'>Do you really want to cancel your subscription?</p>
                            <>
                                <LoadingButton
                                    title='Yes, Cancel Now'
                                    color='blue'
                                    onClick={handleCancelSubscription}
                                    style={{ marginTop: 30 }}
                                    isLoading={loading}
                                />
                            </>
                        </div>
                    </div>
                )
            }
            <div className='billing_header'>
                {getme.billing.paymentMethod.id &&
                    <div className='billing_payment_container'>
                        {getme.billing.paymentMethod.brand === 'visa' ?
                            <img src={Visa} alt='visa' className='biling_card_brand' />
                            : getme.billing.paymentMethod.brand === 'mastercard' ?
                                <img src={Mastercard} alt='mastercard' className='biling_card_brand' />
                                : getme.billing.paymentMethod.brand === 'amex' ?
                                    <img src={Amex} alt='amex' className='biling_card_brand' />
                                    : getme.billing.paymentMethod.brand === 'discover' ?
                                        <img src={Discover} alt='discover' className='biling_card_brand' />
                                        : null
                        }
                        <div style={{ display: 'flex' }}>
                            <p className='biling_card_text' style={{ marginRight: 15 }}>*** {getme.billing.paymentMethod.last4}</p>
                            <p className='biling_card_text'>{getme.billing.paymentMethod.expire}</p>
                        </div>
                        <button className='billing_card_edit_btn' onClick={() => setAddCard(true)}>Edit</button>
                        <button className='billing_card_remove_btn' onClick={() => setAddCard(true)}>Remove</button>
                    </div>
                }
                {addCard ?
                    <>
                        <IoCloseSharp size={35} className='add_item_close' onClick={() => setAddCard(false)} />
                        <Elements stripe={promise}>
                            <CheckoutForm setAddCard={setAddCard} />
                        </Elements>
                    </>
                    :
                    <>
                        {getme.billing.paymentMethod.id ?
                            <CustomButton
                                color='blue'
                                title='Update Payment'
                                onClick={() => setAddCard(true)}
                                style={{ marginBottom: 10 }}
                            />
                            :
                            <CustomButton
                                color='blue'
                                title='Add Payment'
                                onClick={() => setAddCard(true)}
                                style={{ marginBottom: 10 }}
                            />
                        }
                    </>
                }

            </div>
            {getme.billing.subscription.active && (
                <p className='billing_subscription_title'>Subscribed</p>
            )}
            {!getme.freeTrial && !getme.billing.subscription.freeSubscription ? !getme.billing.annualDiscount &&(
                <div className='billing_main_container'>
                    <p className='billing_subscription_title'>elePlate Membership: $45/mo</p>
                    <p className='billing_subscription_description'>Includes IRS tax deduction reports, regulation compliance, and access to 24/7 on-demand donations.</p>

                    {getme.billing.subscription.active && (
                        <CustomButton
                            color='green'
                            title='Cancel Subscription'
                            onClick={() => setCancel(true)}
                        />
                    )}
                    {!getme.billing.subscription.active && (
                        <CustomButton
                            color='green'
                            title='Subscribe'
                            onClick={() => {
                                getme.billing.paymentMethod.id ? setSubscribe(true) : setAddCard(true)
                            }}
                        />
                    )}
                </div>
            ) : null}
            {!getme.freeTrial && !getme.billing.subscription.freeSubscription ? getme.billing.annualDiscount &&(
                <div className='billing_main_container'>
                    <p className='billing_subscription_title'>elePlate Membership: $120/yr</p>
                    <p className='billing_subscription_description'>Includes IRS tax deduction reports, regulation compliance, and access to 24/7 on-demand donations.</p>

                    {getme.billing.subscription.active && (
                        <CustomButton
                            color='green'
                            title='Cancel Subscription'
                            onClick={() => setCancel(true)}
                        />
                    )}
                    {!getme.billing.subscription.active && (
                        <CustomButton
                            color='green'
                            title='Subscribe'
                            onClick={() => {
                                getme.billing.paymentMethod.id ? setSubscribe(true) : setAddCard(true)
                            }}
                        />
                    )}
                </div>
            ) : null}
            <div className='billing_footer' >
                <img className='billing_stripe_image' src={StripeSecure} alt='stripe' />
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchCreateSubscription: (user, onSuccess, onError) =>
        dispatch(createSubscription({ user }, onSuccess, onError)),
    dispatchCreateAnnualDiscountSubscription: (user, onSuccess, onError) =>
        dispatch(createAnnualDiscountSubscription({ user }, onSuccess, onError)),
    dispatchCancelSubscription: (user, onSuccess, onError) =>
        dispatch(cancelSubscription({ user }, onSuccess, onError)),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(Billing)