import React, { useState } from 'react'
import { connect } from "react-redux"
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { getMe } from '../../redux/actions/user'
import { Ellipsis } from 'react-css-spinners'
import { createPaymentMethod } from '../../redux/actions/payments'
import { IoCloseSharp } from "react-icons/io5"

import "./styles.css"

const CheckoutForm = ({ getme, disptachGetMe, dispatchCreatePaymentMethod, setAddCard }) => {
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const stripe = useStripe();
    const elements = useElements();

    const cardStyle = {
        hidePostalCode: true,

        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                fontFamily: 'Arial, sans-serif',
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    }
    const handleSubmmit = async (event) => {
        event.preventDefault()
        setLoading(true)

        const cardElement = elements.getElement(CardElement)

        let { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement
        })

        error && setError(error.message); setLoading(false)
        paymentMethod && (setError(''))

        dispatchCreatePaymentMethod(
            getme.id,
            paymentMethod.id,
            paymentMethod.card.brand,
            paymentMethod.card.exp_month,
            paymentMethod.card.exp_year,
            paymentMethod.card.last4,
            (response) => {
                disptachGetMe();
                setLoading(false);
                setAddCard(false);
            },
            (error) => { setLoading(false); setError(error.data.raw.message) }
        )
    }

    console.log(error)

    return (
        <div className='card_modal'>
            <form className='payment_form' onSubmit={handleSubmmit}>
                <IoCloseSharp size={35} className='card_modal_close' onClick={() => setAddCard(false)} />
                <p className='card_modal_title'>Add Payment</p>
                <CardElement id="card-element" options={cardStyle} />
                <button className='checkout_add_card_button' disabled={loading ? true : false}>
                    {
                        !loading ? 'Add Payment' : <span> <Ellipsis color="#FFF" size={38} style={{ marginTop: 3 }} /></span>
                    }
                </button>
                <div>
                    {error && (<p className="payment_form_card_error">{error}</p>)}
                </div>
            </form>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchCreatePaymentMethod: (user, payment_id, brand, exp_month, exp_year, last4, onSuccess, onError) =>
        dispatch(createPaymentMethod({ user, payment_id, brand, exp_month, exp_year, last4 }, onSuccess, onError)),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)