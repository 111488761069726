import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import { format, parseISO} from 'date-fns';
import { IoCloseSharp, IoChevronDownOutline, IoAddSharp } from "react-icons/io5"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
// import { Ionicons } from '@expo/vector-icons';

import CustomButton from '../CustomButton';
import RecipientDropdown from '../RecipientDropdown'
import { getMe } from '../../redux/actions/user';
import { createSchedule, updateSchedule, runUpcomingJob } from '../../redux/actions/schedule';
import { getRecipientsAvailable } from '../../redux/actions/delivery';
import PlusIcon from '../../assets/icons/plus.svg'
import ScheduleSelect from '../scheduleSelect';


import './styles.css'

// Import additional packages here like react-icons, react-modal, etc.

const AddToSchedule = ({
    setAddToSchedule,
    editSchedule,
    getme,
    disptachGetMe,
    dispatchCreateSchedule,
    dispatchRunUpcomingJob,
    editCadence,
    editDate,
    editTime,
    editRecipient,
    scheduleId,
    dispatchUpdateSchedule,
    dispatchAvailableRecipients,
}) => {
    const [cadence, setCadence] = useState('');
    const [weekday, setWeekday] = useState([]);
    const [displayDay, setDisplayDay] = useState('');
    const [monthlyday, setMonthlyday] = useState('');
    const [singleday, setSingleday] = useState(moment().add(1, 'days').toDate());
    const [loading, setLoading] = useState(false);
    const [time, setTime] = useState(new Date());
    const [displayTime, setDisplayTime] = useState(new Date())
    const [selectedWeekdayNames, setSelectedWeekdayNames] = useState([]);
    const [recipientsAvailable, setRecipientsAvailable] = useState([])
    const [selectedRecipient, setSelectedRecipient] = useState('');
    const [selectedDays, setSelectedDays] = useState([]);

    
    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
    
    useEffect(() => {
        if (scheduleId) {
            setCadence(editCadence);
            const [hour, minute, second] = editTime.split(":").map(Number);
            const editTimeDate = new Date();
            editTimeDate.setHours(hour, minute, second, 0);
            setTime(editTimeDate);
            const formattedTime = format(editTimeDate, "yyyy-MM-dd'T'HH:mm:ss");
            const convertTime = parseISO(formattedTime);
            setDisplayTime(convertTime);
            setSelectedRecipient(editRecipient);

            if (editCadence === 'weekly') {
                setWeekday(editDate);
                setDisplayDay(editDate.toUpperCase())
                setSelectedWeekdayNames(editDate.slice(0, 3).toUpperCase());
            } else if (editCadence === 'single') {
                const [year, month, day] = editDate.split('-');
                const singledayDate = new Date(year, month - 1, day);
                singledayDate.setDate(singledayDate.getDate() + 1);
                setSingleday(singledayDate);
                console.log('single day date', singledayDate, singleday)
            }
        }
    }, [scheduleId, editCadence, editDate, editTime, editRecipient]);

    useEffect(() => {
        const fetchData = async () => {
            // setLoading(true);  // 2. Set loading to true before fetching
    
            let apiDay;
            if (cadence === 'single') {
                apiDay = singleday;
            } else if (cadence === 'weekly') {
                apiDay = weekday;
            }
    
            await handleAvailableRecipients(apiDay, `${moment(time).format("HH:mm:ss")}`);  // 3. Use await to ensure completion
    
            // setLoading(false);  // 4. Set loading to false after fetching
        };
    
        fetchData();

        console.log('recipients available', recipientsAvailable)
    },[singleday, weekday, time])

    

    useEffect(() => {
    //     // Check if the current selected recipient is in the updated recipientsAvailable array
        const isSelectedRecipientAvailable = recipientsAvailable.some(
          (recipient) => recipient.customerName === selectedRecipient.customerName
        );
    
        if (!isSelectedRecipientAvailable) {
          // Set to the first available recipient or null
          if(recipientsAvailable.length > 0) {
            setSelectedRecipient(recipientsAvailable[0]);
          } else {
            if (!loading) {
                setSelectedRecipient({customerName: 'No recipients available'});
            }
          }
          
        }
    }, [recipientsAvailable, loading]);

    

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 14);

    
    const handleSelectItem = (selectedItems) => {
        const selectedDays = selectedItems.map((index) => weekdays[index.row]);
        const sortedDays = selectedDays.sort((a, b) => {
            const order = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            return order.indexOf(a) - order.indexOf(b);
        });
        setWeekday(sortedDays);
        setSelectedWeekdayNames(
            sortedDays
                .map(day => day.slice(0, 3).toUpperCase())
                .join(', ')
        );
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    
    const onTimeChange = (selectedTime) => {
        const currentTime = selectedTime || time;
        console.log('selected time', selectedTime)
        setDisplayTime(selectedTime)
        if (currentTime) {
            currentTime.setSeconds(0);
            currentTime.setMilliseconds(0);
            setTime(currentTime);
            const formattedTime = format(currentTime, "yyyy-MM-dd'T'HH:mm:ss");
            const convertTime = parseISO(formattedTime);
            
            const momentDate = moment(singleday);

            // Set the time part from currentTime
            const momentDateTime = momentDate.set({
              hour: currentTime.getHours(),
              minute: currentTime.getMinutes(),
              second: 0,
              millisecond: 0
            });
            console.log("momentDateTime:", momentDateTime);
            // Interpret that datetime as if it's in America/Los_Angeles timezone
            const laTime = momentDateTime.clone().tz("America/Los_Angeles", true);
        
            // Convert back to UTC
            const utcTime = laTime.clone().tz("UTC");
        
            // Get the ISO string
            const isoStringInUTC = utcTime.toISOString();
        
            console.log("Adjusted time in UTC:", isoStringInUTC);
            console.log("currentTime:", utcTime);
          }
  


    };

    const cadences = ['single', 'weekly']
    const weekdays = [
        'monday', 
        'tuesday', 
        'wednesday', 
        'thursday', 
        'friday', 
        'saturday', 
        'sunday'
    ]
    const monthlydays = ['1st', '2nd', '3rd', '4th']
    const biweeklyOptions = [
        '1st and 2nd', 
        '1st and 3rd', 
        '1st and 4th', 
        '2nd and 3rd', 
        '2nd and 4th', 
        '3rd and 4th'
    ]
    // const CustomOption = ({ innerProps, isDisabled, isSelected, label }) => (
    //     <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
    //         <input type="checkbox" checked={isSelected} disabled={isDisabled} readOnly style={{ marginRight: 8 }} />
    //         <div style={{ color: '#31378B' }}>{label}</div>
    //     </div>
    // );
    const Checkbox = ({ isChecked }) => (
        <div style={{
          display: 'inline-block',
          width: '16px',
          height: '16px',
          borderRadius: '3px',
          border: '2px solid #31378B',
          backgroundColor: isChecked ? '#31378B' : '#fff',
          marginRight: '8px',
          marginLeft: '8px',
        }} />
      );

    const CustomOption = ({ innerProps, isSelected, label }) => (
        <div {...innerProps} style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox isChecked={isSelected} />
          <div style={{ color: '#31378B' }}>{label}</div>
        </div>
      );

    const customStyles = {
        control: (base, state) => ({
            ...base,
            minWidth: 314,
            maxWidth: 320,
            width: '50%',
            marginBottom: 10,
            alignSelf: 'center',
            border: state.isFocused ? '1px solid #31378B' : '1px solid #31378B',
            boxShadow: state.isFocused ? '0 0 0 0px #31378B' : 0,
            '&:hover': {
              border: '1px solid #EB896C',
            },
        }),
        multiValue: (styles) => ({
            ...styles,
            backgroundColor: '#31378B',
            width: '31%',  // Ensures 3 labels per row
            justifyContent: 'center',
            // padding: '5px',
        }),
        multiValueLabel: (styles) => ({
            ...styles,
            color: 'white',  // Sets text color to white
            padding: '5px',
        }),
        // ... other styles if needed
        option: (base, state) => ({
            ...base,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingLeft: '8px',
            
        }),
      };

      const options = weekdays.map(day => ({ label: day.toUpperCase(), value: day }));

  const handleChange = (selectedOptions) => {
    const selectedItems = selectedOptions.map(option => ({
      row: weekdays.indexOf(option.value),
    }));
    handleSelectItem(selectedItems);
  };
    
      
    // const cadences = ['Single', 'Weekly', 'Bi-Weekly', 'Monthly']
    

    // const pickerSelectStyles = {
    //     inputIOS: {
    //       ...styles.scheduleSelector,
    //     },
    //     inputAndroid: {
    //       ...styles.scheduleSelector,
    //     },
    //     iconContainer: {
    //       top: 14,
    //       right: 20,
    //     },
    // };

    function addDays(days) {
        var result = new Date();
        result.setDate(result.getDate() + days)
        return result
    }

    const clickHandler = (array, value, setter) => {
        if (array.includes(value)) {
            return
        } else {
            const newArray = [...array, value]
            setter(newArray)
        }
    }

    const handleRemoveItem = (array, value, setter) => {
        setter(array.filter(item => item !== value))
    }

    const handleRunUpcoming = () => {
        setLoading(true);
        console.log('hit run upcoming')
        dispatchRunUpcomingJob(
            () => { 
                disptachGetMe(); 
                setLoading(false); 
                // setAlert(false); 
                console.log('Successfully added to schedule.');
            },
            (error) => {  
                alert('Error adding to schedule', 'Please try again'); 
                console.log(error); 
                setLoading(false) 
            }
        );
    };

    const handleCreateSchedule = () => {
        setLoading(true)
        if (selectedRecipient === "No recipients available") {
            alert("No Recipient Available. Please try another date or time.");
            setLoading(false);
            return;
        }
        
        let date

        switch (cadence) {
            case 'single':
                const rowDate = moment(singleday)
                date = rowDate.format("YYYY-MM-DD")
                break;
            case 'weekly':
                date = weekday
                break
            // case 'bi-weekly':
            //     date = { weekOfTheMonth: monthlyday, dayOfTheWeek: weekday }
            //     break
            case 'monthly':
                date = { weekOfTheMonth: Number(monthlyday), dayOfTheWeek: weekday[0] }
                break
        }
        if (!cadence || !date || !time) {
            alert('Please select cadence, date, and time');
            setLoading(false)
            return
        } else {
            dispatchCreateSchedule(
                cadence,
                date,
                moment(time).format().slice(11, 19),
                getme.id,
                getme.recipients,
                () => { 
                    disptachGetMe(); 
                    setLoading(false); 
                    setAddToSchedule(false); 
                    console.log(getme.recipients);
                    handleRunUpcoming();
                },
                (error) => {  
                    alert('Error', 'Please select cadence, date, and time'); 
                    console.log(error); 
                    console.log(getme.recipients)
                    setLoading(false) 
                }
            )
        }
    }

    const handleUpdateSchedule = () => {
        setLoading(true)
        
        if (selectedRecipient === "No recipients available") {
            alert("No Recipient Available. Please try another date or time.");
            setLoading(false);
            return;
        }
    
        let date

        switch (cadence) {
            case 'single':
                const rowDate = moment(singleday)
                date = rowDate.format("YYYY-MM-DD")
                break;
            case 'weekly':
                date = weekday
                break
            case 'bi-weekly':
                date = { weekOfTheMonth: monthlyday, dayOfTheWeek: weekday }
                break
            case 'monthly':
                date = { weekOfTheMonth: Number(monthlyday), dayOfTheWeek: weekday }
                break
            default:
                break;
        }

        if (!date || !time) {
            console.log('Please select date and time')
            setLoading(false)
            return
        }

        function isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        dispatchUpdateSchedule(
            scheduleId,
            cadence,
            date,
            moment(time).format().slice(11, 19),
            getme.atCost,
            getme.instruction,
            isJsonString(selectedRecipient) ? JSON.parse(selectedRecipient) : selectedRecipient,
            getme.restaurant,
            getme.address,
            getme.phone,
            getme.billing.driverTip,
            getme.freeTrial,
            getme.id,
            getme.billing,
            () => { 
                disptachGetMe(); 
                setAddToSchedule(false);
                handleRunUpcoming();
                setLoading(false) 
            },
            (error) => { console.log(error); setLoading(false) }
        )
    }

    // const handleAvailableRecipients = async (day, time) => {
    //     setLoading(true);

    //     let date;
        
    //     switch (cadence) {
    //         case 'single':
    //         date = moment(day).format("YYYY-MM-DD");
    //         break;
    //         case 'weekly':
    //         date = day;
    //         break;
    //         case 'bi-weekly':
    //         date = day;
    //         break;
    //         case 'monthly':
    //         date = { dayOfTheWeek: day };
    //         break;
    //     }

    //     try {
    //         const response = await dispatchAvailableRecipients(
    //         getme.recipients,
    //         "schedule",
    //         cadence,
    //         date,
    //         time
    //         );
    //         console.log("API Response:", response);
    //         setRecipientsAvailable(response.data);
    //     } catch (error) {
    //         console.log(error);
    //         setRecipientsAvailable([]);
    //     }

    //     setLoading(false);
    // }

    const handleAvailableRecipients = (day, time) => {

        let date
        // console.log('handle time', time)
        // const pickupTime = moment(time).format("HH:mm:ss")
        // console.log('handle time 2', time)

        switch (cadence) {
            case 'single':
                date = moment(day).format("YYYY-MM-DD")
                break;
            case 'weekly':
                date = day
                break
            case 'bi-weekly':
                date = day
                break
            case 'monthly':
                date = { dayOfTheWeek: day }
                break
        }
        dispatchAvailableRecipients(
            getme.recipients,
            "schedule",
            cadence,
            date,
            time,
            (response) => { console.log('in dis', getme.recipients, date, time); setRecipientsAvailable(response.data) },
            (error) => { console.log(error); setRecipientsAvailable([]) }
        )
    }

    return (
        <div className="modal_edit_donation">
            <div className="modal_edit_donation_container">
                <div className="section_title">
                    <p className="modal_edit_donation_title">
                        {scheduleId ? (cadence.toUpperCase() + " DONATION") : 'ADD TO SCHEDULE'}
                    </p>
                    {/* <IoCloseSharp className='group_icon_close' onClick={() => setAddToSchedule(false)} /> */}
                </div>
                <div className="section_inputs">
                    {scheduleId ?  (
                        <> 
                            {/* <RecipientDropdown
                                recipientsList={recipientsAvailable}
                                recipient={selectedRecipient}
                                setRecipient={setSelectedRecipient}
                                style={{ marginBottom: 10 }} 
                            />  */}
                        </>
                    )  : (
                        <div className='history_report_select_container'>
                            {/* <p className="modal_edit_schedule_label">SELECT CADENCE</p> */}
                            <IoChevronDownOutline className='group_food_category_selector_icon' />
                            <select
                                value={cadence}  // controlled value
                                className='cadence_selector'
                                onChange={(e) => {
                                    setCadence(e.target.value);  // update the state
                                }}
                                style={{color: '#31378B'}}
                            >
                                <option value="" disabled hidden>SELECT CADENCE</option>  // placeholder option
                                {cadences.map((item, index) => (
                                    <option key={index} value={item}>
                                        {item.toUpperCase()}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    <div className="modal_edit_donation_input_container">
                        {cadence === 'single' && (
                            <>
                                <label className="schedule-dropdown-label">DATE</label>
                                <DatePicker 
                                    id="date-picker"
                                    selected={singleday} 
                                    onChange={(date) => setSingleday(date)} 
                                    minDate={tomorrow}
                                    maxDate={nextWeek}
                                    className="modal_edit_donation_input"
                                />
                            </>
                        )}
                        {cadence === 'weekly' && (
                            <>
                            {/* <div className='schedule_selector_container'>
                                <IoAddSharp className='group_food_category_selector_icon' />
                                <select 
                                    className='schedule_selector' 
                                    onChange={(e) => clickHandler(weekday, e.target.value, setWeekday)}
                                >
                                    <option value="">ADD DAYS</option>
                                    {weekdays.map((item) =>
                                        <option key={item} value={item.toLocaleLowerCase()}>{item}</option>
                                    )}
                                </select>
                            </div> */}
                            {/* <div>
                                <div> */}
                                {scheduleId ? (
                                    <> 
                                        <RecipientDropdown
                                            recipientsList={recipientsAvailable}
                                            recipient={selectedRecipient}
                                            setRecipient={setSelectedRecipient}
                                            style={{ marginBottom: 10 }} 
                                        /> 
                                    </>
                                ) : (
                                    <>
                                        <label className="schedule-dropdown-label">RECURRING DAYS</label>
                                        <Select
                                            components={{ Option: CustomOption }}
                                            isMulti
                                            options={options}
                                            onChange={handleChange}
                                            value={weekday.map(day => ({
                                                label: day.slice(0, 3).toUpperCase(),
                                                value: day,
                                            }))}
                                            placeholder="SELECT DAYS"
                                            styles={customStyles}
                                            hideSelectedOptions={false}
                                        />
                                    </>
                                    )}
                                {/* </div>
                            </div> */}
                            {/* <div className='schedule_weekday_override'>
                                <div className='schedule_weekday_container'>
                                    {weekday.map((item) =>
                                        <div 
                                            key={item} 
                                            className="schedule_weekday_container_label" 
                                            onClick={() => handleRemoveItem(weekday, item, setWeekday)}
                                        >
                                            <p className='schedule_weekday_label'>{item.slice(0, 3)}</p>
                                            <IoCloseSharp size={14} style={{ marginRight: 5 }} />
                                        </div>
                                    )}
                                </div>
                            </div> */}
                                {/* <RecipientDropdown
                                    recipientsList={recipientsAvailable}
                                    recipient={selectedRecipient}
                                    setRecipient={setSelectedRecipient}
                                    style={{ marginBottom: 10 }}
                                /> */}
                            </>
                        )}
                        {displayDay && <p className="modal_edit_donation_title" style={{color: '#ED896E', fontSize: 20}}>EVERY {displayDay.toUpperCase()}</p>}
                        <p className="modal_edit_schedule_label">TIME</p>
                        <div style={{alignSelf: 'center'}}>
                            <DatePicker
                                selected={displayTime}
                                onChange={(selectedTime) => onTimeChange(selectedTime)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                dateFormat="h:mm aa"
                                className="modal_edit_donation_input"
                                style={{textAlign: 'center', alignSelf: 'center'}}
                            />
                        </div>
                    </div>
                </div>
                <div className="section_buttons">
                    <div className="modal_edit_donation_input_container">
                        <button className="modal_edit_button_update" onClick={scheduleId ? handleUpdateSchedule : handleCreateSchedule}> 
                            {scheduleId ? 'Confirm Change' : 'Add to Schedule'}
                        </button>
                        <button className="modal_edit_button_cancel" onClick={() => setAddToSchedule(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
    
}
    

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchCreateSchedule: (cadence, date, time, ownerId, recipients, onSuccess, onError) =>
        dispatch(createSchedule({ cadence, date, time, ownerId, recipients }, onSuccess, onError)),
    dispatchUpdateSchedule: (scheduleId, cadence, date, time, atCost, instruction, recipient, restaurantName, restaurantAddress, restaurantPhoneNumber, driverTip, freeTrial, ownerId, paymentDetails, onSuccess, onError) =>
        dispatch(updateSchedule(scheduleId, { cadence, date, time, atCost, instruction, recipient, restaurantName, restaurantAddress, restaurantPhoneNumber, driverTip, freeTrial, ownerId, paymentDetails }, onSuccess, onError)),
    dispatchRunUpcomingJob: (onSuccess, onError) => 
        dispatch(runUpcomingJob(onSuccess, onError)),
    dispatchAvailableRecipients: (recipients, type, cadence, date, period, onSuccess, onError) =>
        dispatch(getRecipientsAvailable({ recipients, type, cadence, date, period }, onSuccess, onError)),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(AddToSchedule);

