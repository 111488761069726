import React, { useEffect, useState, useRef } from 'react'
import { updateFoodItem } from '../../redux/actions/foodItem'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"

import { deleteFoodItem } from '../../redux/actions/foodItem'
import { updateScheduleBag } from '../../redux/actions/schedule'
import { getMe, updateUser } from '../../redux/actions/user'

import { TiPencil } from "react-icons/ti"
import { IoCloseSharp } from "react-icons/io5"
import { FaRegTrashAlt } from "react-icons/fa"
import { NumericFormat } from 'react-number-format'
import CheckIcon from '../../assets/icons/check.png'

import './styles.css'

const FoodItem = ({ 
    cost, 
    fmv, 
    itemType, 
    taxSaving, 
    itemId,
    scheduledDonation,
    donationId,
    disptachGetMe, 
    dispatchUpdateItemFood, 
    dispatchUpdateUser, 
    dispatchDeleteFoodItem,
    dispatchUpdateScheduleBag,
    getme }) => {
    const [edit, setEdit] = useState(false)
    const [editPrice, setEditPrice] = useState(false)
    const [editCost, setEditcost] = useState('')
    const [editFmv, setEditFmv] = useState('')
    const [lbs, setLbs] = useState('')
    const [deleteItem, setDeleteItem] = useState(false)
    const [open, setOpen] = useState(false)
    const [fullCart, setFullCart] = useState(false)
    let menuRef = useRef()


    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])
    
    let findBag
    let bag
    let currentLbs
    let checkBagLbs
    if (scheduledDonation) {
        findBag = getme.donations.find(donation => donation.id === donationId).bag
        bag = findBag.items.find(item => item.itemId === itemId)
        currentLbs  = !bag?.lbs ? 0 : bag?.lbs
        checkBagLbs = (findBag.totals.totalWeight - currentLbs) + Number(lbs)
    } else {
        bag = getme.bag.items.find(item => item.itemId === itemId)
        currentLbs  = !bag?.lbs ? 0 : bag?.lbs
        checkBagLbs = (getme.bag.totals.totalWeight - currentLbs) + Number(lbs)
    }

    const editFoodItem = () => {
        const updatedCost = editCost ? editCost : cost
        const updatedFmv = editFmv ? editFmv : fmv

        dispatchUpdateItemFood(
            updatedCost,
            updatedFmv,
            itemId,
            () => { disptachGetMe(); setEditPrice(false); setEdit(false) },
            (error) => console.log(error)
        )
    }

    // const currenLbs = !bag?.lbs ? 0 : bag?.lbs
    // const checkBagLbs = (getme.bag.totals.totalWeight - currenLbs) + Number(lbs)

    // const updateBag = (action) => {
    //     if (checkBagLbs > 300 && action === 'add') {
    //         setFullCart(true)
    //     } else {
    //         const item = {
    //             itemId: itemId,
    //             itemType: itemType,
    //             cost: cost,
    //             fmv: fmv,
    //             taxSaving: taxSaving,
    //             lbs: Number(lbs),
    //         }
    //         dispatchUpdateUser(
    //             action,
    //             item,
    //             getme.id,
    //             () => { disptachGetMe(); setEdit(false) },
    //             (error) => console.log(error)
    //         )
    //     }
    // }
    const updateBag = (action) => {
        if (action === 'add' || 'remove') {
            if (checkBagLbs > 300 && action === 'add') {
                alert(
                    'Donation must be 300lbs or less',
                    'If your donation is bigger, please split into a second delivery.'
                )
            } else {
                const item = {
                    itemId: itemId,
                    itemType: itemType,
                    cost: cost,
                    fmv: fmv,
                    taxSaving: taxSaving,
                    lbs: Number(lbs),
                }
                if (scheduledDonation)  {
                    dispatchUpdateScheduleBag(
                        action, 
                        item, 
                        findBag, 
                        donationId, 
                        () => disptachGetMe(), 
                        (error) => console.log(error)
                    );
                    setEdit(false)
                } else {
                    dispatchUpdateUser(
                        action,
                        item,
                        getme.id,
                        () => { disptachGetMe(); setEdit(false) },
                        (error) => console.log(error)
                    )
                }
            }
        } else if (action === 'update') {
            console.log('update')
        }
    }

    const deleteFoddItem = () => {
        dispatchDeleteFoodItem(
            itemId,
            () => { disptachGetMe(); setDeleteItem(false) },
            (error) => console.log(error)
        )
    }

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef?.current?.contains(event.target)) {
                setOpen(false)
                setEdit(false)
                setEditPrice(false)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const lbFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(Math.round(Number(taxSaving) * 100) / 100)

    return (
        <>
            {deleteItem && (
                <div className='bag_item_modal'>
                    <div className='bag_item_modal_container'>
                        <IoCloseSharp size={35} className='subscribe_modal_icon_close' onClick={() => setDeleteItem(false)} />
                        <p className='subscribe_modal_title'>Are you sure?</p>
                        <p className='bag_item_modal_subtitle'>Do you really want to delete the item: <br /> {itemType}</p>
                        <>
                            <button className='bag_modal_button' onClick={deleteFoddItem}>Delete</button>
                            <button onClick={() => setDeleteItem(false)} className='checkout_estimate_modal_button_retry'>Cancel</button>
                        </>
                    </div>
                </div>
            )}

            {fullCart && (
                <div className='bag_item_modal'>
                    <div className='bag_item_modal_container'>
                        <IoCloseSharp size={35} className='checkout_estimate_modal_icon' onClick={() => setFullCart(false)} />
                        <p className='checkout_estimate_modal_title'>Limit Reached</p>
                        <p className='bag_item_modal_subtitle'>Donation must be under 300 pounds and fit in the backseat of a sedan.</p>
                        <p className='bag_item_modal_subtitle'>Please reduce the weight. You can create another donation after you complete <br /> this one!</p>
                        <Link className='bag_modal_button' to="/checkout">Conclude Donation</Link>
                    </div>
                </div>
            )}

            <div className='bag_item' ref={menuRef}>
                {edit && open ?
                    <div className={bag?.itemId === itemId ? 'bag_item_edit_added' : 'bag_item_edit'}>
                        <IoCloseSharp className='bag_item_icon_close' onClick={() => {
                            {
                                setOpen(false);
                                setEdit(false);
                                setEditPrice(false);
                            }
                        }} />
                        <p className='bag_item_title'>{itemType}</p>
                        {editPrice ?
                            <>
                                <div className='bag_item_edit_add'>
                                    <p className='bag_item_subtitle'>COST:</p>
                                    <NumericFormat
                                        className='bag_item_edit_add_input'
                                        onValueChange={(e) => setEditcost(Number(e.value))}
                                        placeholder={editCost}
                                        value={cost}
                                        valueIsNumericString={true}
                                        type="text"
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        maxLength={11}
                                        allowNegative={false}
                                    />
                                </div>
                                <div className='bag_item_edit_add'>
                                    <p className='bag_item_subtitle'>FMV:</p>
                                    <NumericFormat
                                        className='bag_item_edit_add_input'
                                        onValueChange={(e) => setEditFmv(Number(e.value))}
                                        placeholder={editFmv}
                                        value={fmv}
                                        type='text'
                                        valueIsNumericString={true}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                        maxLength={11}
                                        allowNegative={false}
                                    />
                                </div>
                                <img src={CheckIcon} alt='check' className='bag_item_edit_add_check_icon' onClick={editFoodItem} />
                            </>
                            :
                            <>
                                <div className='bag_item_edit_add'>
                                <NumericFormat
                                        className='bag_item_edit_add_input'
                                        onValueChange={(e) => setLbs(Number(e.value))}
                                        placeholder={bag?.lbs}
                                        type='number'
                                        valueIsNumericString={true}
                                        fixedDecimalScale={true}
                                        maxLength={11}
                                        allowNegative={false}
                                    />
                                    <p className='bag_item_subtitle'>lbs</p>
                                    <img src={CheckIcon} alt='check' className='bag_item_edit_add_check_icon' onClick={() => updateBag('add')} />
                                </div>
                                {bag?.itemId === itemId && (
                                    <FaRegTrashAlt className='bag_item_icon_edit' onClick={() => { updateBag('remove') }} />

                                )}
                                {bag?.itemId !== itemId && (
                                    <>
                                        <div className='bag_item_edit_section'>
                                            <p className='bag_item_lb_text'>{lbFormat}/LB</p>
                                            <TiPencil className='bag_item_icon_edit' onClick={() => setEditPrice(true)} />
                                        </div>
                                        <button className='bag_item_edit_price_button' onClick={() => setDeleteItem(true)} >Remove</button>
                                    </>
                                )
                                }
                            </>
                        }
                    </div>
                    : bag?.itemId !== itemId ?
                        <div className='bag_item_empty' onClick={() => { setEdit(true); setOpen(true) }}>
                            <p className='bag_item_title'>{itemType}</p>
                            <p className='bag_item_title'>{lbFormat}/LB</p>
                        </div>
                        :
                        <div className='bag_item_filled' onClick={() => { setEdit(true); setOpen(true) }}>
                            <p className='bag_item_title'>{itemType}</p>
                            <p className='bag_item_title'>{bag?.lbs} LBS</p>
                            <img src={CheckIcon} alt='check' className='bag_item_edit_add_check_icon' />
                        </div>
                }
            </div>
        </>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateItemFood: (cost, fmv, itemId, onSuccess, onError) =>
        dispatch(updateFoodItem({ cost, fmv }, itemId, onSuccess, onError)),
    dispatchUpdateUser: (action, foodItem, userId, onSuccess, onError) =>
        dispatch(updateUser({ action, foodItem }, userId, onSuccess, onError)),
    dispatchDeleteFoodItem: (itemId, onSuccess, onError) =>
        dispatch(deleteFoodItem(itemId, onSuccess, onError)),
    dispatchUpdateScheduleBag: (action, foodItem, currentBag, donationId, onSuccess, onError) =>
        dispatch(updateScheduleBag({ action, foodItem, currentBag, donationId }, onSuccess, onError)),
    disptachGetMe: () => dispatch(getMe()),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(FoodItem)
