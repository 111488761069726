import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getMe } from '../../redux/actions/user'
import ShipdayTracking from '../../components/ShipdayTracking'
import CarRightIcon from '../../assets/icons/car_right.svg'

import './styles.css'


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    }
}

const Delivery = ({ getme }) => {

    const openDonations = getme.donations.filter(item => item.status === 'open')
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <div className='delivery'>
            {windowDimensions.width < 800 && (
                <div className='header_page_title_container'>
                    <img src={CarRightIcon} className='header_page_title_icon_car' />
                    <p className='header_page_title'>Ongoing Delivery</p>
                </div>
            )}
            <div className='delivery_container'>
                {openDonations.map((item) =>
                    <ShipdayTracking
                        key={item.id}
                        donationId={item.id}
                        deliveryStatus={item.deliveryDetails.deliveryStatus}
                        dropoffTimeEstimated={item.deliveryDetails.dropoffTimeEstimated}
                        pickupTimeEstimated={item.deliveryDetails.pickupTimeEstimated}
                        trackingLink={item.trackingLink}
                        orderId={item.deliveryDetails.orderId}
                        donation={item}
                    />
                )}
            </div>
        </div>
    )
}


const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(Delivery)