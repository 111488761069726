import React, { useState } from 'react'
import { connect } from 'react-redux'
import { IoCloseSharp } from "react-icons/io5"
import { Link } from "react-router-dom"
import { getMe } from '../../redux/actions/user'
import { createDonation } from '../../redux/actions/donation'
import { useNavigate } from "react-router-dom"
import CustomButton from '../CustomButton'
import LoadingButton from '../LoadingButton'

import './styles.css'

const CheckoutEstimate = ({ getme, setConfirm, setEstimate, setTrackingLink, setLink, delivery, dispathCreateDonation, disptachGetMe, express, width}) => {
    const [loading, setLoading] = useState(false)
    const [paymentError, setPaymentError] = useState(false)
    const navigate = useNavigate()

    const createDonation = () => {
        setLoading(true)

        dispathCreateDonation(
            getme.freeTrial,
            express,
            getme.id,
            getme.bag,
            delivery.data.deliveryEstimate.toFixed(2),
            delivery.data.deliveryServiceName,
            delivery.data.orderId,
            delivery.data.orderNumber,
            delivery.data.recipientId,
            delivery.data.recipientName,
            delivery.data.recipientEin,
            getme.billing,
            (response) => {
                disptachGetMe()
                setLoading(false)
                setTrackingLink(true)
                setEstimate(false)
                setLink(response.data.trackingLink)
                navigate('/delivery')
            },
            () => { setLoading(false); setPaymentError(true) }
        )
    }

    return (
        <div className='checkout_confirm'>
            {paymentError && (
                <div className='checkout_confirm_modal'>
                    <div className='checkout_confirm_modal_container'>
                        <IoCloseSharp size={35} className='checkout_estimate_modal_icon' onClick={() => { setPaymentError(false) }} />
                        <p className='checkout_estimate_modal_title'>Payment failure!</p>
                        <p className='checkout_confirm_modal_subtitle'>Please try again or update your payment.</p>
                        <>
                            <Link to="/billing" className='checkout_estimate_modal_button_update'>Update Payment</Link>
                            <button onClick={() => setPaymentError(false)} className='checkout_estimate_modal_button_retry'>Try Again</button>
                        </>
                    </div>
                </div>
            )}

            <div className='checkout_header' style={{ borderBottom: 'none' }}>
                <div className='home_info_container'>
                    <div className='donate_info_box'>
                        <p className='donate_number_title'>${delivery.data.deliveryEstimate.toFixed(2)}</p>
                        <p className='donate_text_subtitle'>Delivery Fee</p>
                    </div>
                    <div className='donate_info_box'>
                        <p className='donate_number_title'>{delivery.data.deliveryServiceName}</p>
                        <p className='donate_text_subtitle'>Delivery Service</p>
                    </div>
                    <div className='donate_info_box'>
                        <p className='donate_number_title'>{delivery.data.totalWeight} lbs</p>
                        <p className='donate_text_subtitle'>Total weight</p>
                    </div>
                </div>
                <LoadingButton
                    isLoading={loading}
                    title='Confirm and Donate'
                    onClick={createDonation}
                    color='blue'
                    style={{ marginBottom: 10 }}
                />
                <CustomButton
                    title='Cancel'
                    onClick={() => { setEstimate(false); setConfirm(false) }}
                    color='white'
                />
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispathCreateDonation: (freeTrial, express, ownerId, bag, deliveryEstimate, deliveryServiceName, orderId, orderNumber, recipientId, recipientName, recipientEin, paymentDetails, onSuccess, onError) =>
        dispatch(createDonation({ freeTrial, express, ownerId, bag, deliveryEstimate, deliveryServiceName, orderId, orderNumber, recipientId, recipientName, recipientEin, paymentDetails }, onSuccess, onError)),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutEstimate)
