import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getMe } from '../../redux/actions/user'
import { deleteSchedule } from '../../redux/actions/schedule'
import EditSchedule from '../EditSchedule'
import { TiPencil, } from "react-icons/ti"
import { FaRegTrashAlt } from "react-icons/fa"
import AddToSchedule from '../AddToSchedule';

import EditIcon from '../../assets/icons/edit.svg'
import TrashIcon from '../../assets/icons/bag.svg'


import moment from 'moment'

import './styles.css'

const ScheduleCard = ({ getme, schedule, disptachGetMe, dispatchUpdateSchedule, dispatchDeleteSchedule }) => {
    const [loading, setLoading] = useState(false);
    const [addToSchedule, setAddToSchedule] = useState(false);

    const date = moment(new Date()).format('YYYY-MM-DD')

    const deleteSchedule = () => {
        if (window.confirm("Are you sure you want to delete this from your schedule?")) {
            dispatchDeleteSchedule(
                schedule.id,
                () => disptachGetMe(),
                (error) => console.log(error)
            );
        }
    };


    function dayParser(day){
        let result
        switch (day) {
            case 1:
                result = '1st'
                break;
            case 2:
                result = '2nd'
                break
            case 3:
                result = '3rd'
                break
            case 4:
                result = '4th'
                break
        }
        return result
    }

    return (
        <div className="history-card">
            <div className="history-card-header">
                <div className="schedule-card-date-container">
                    <div>
                        {schedule.cadence === 'single' && 
                            <h1 className="history-card-text-date">
                                Single Donation 
                            </h1>
                        }
                        {schedule.cadence === 'weekly' && 
                            <h1 className="history-card-text-date">
                                Every {schedule.date} 
                            </h1>
                        }
                        {schedule.cadence === 'bi-weekly' && 
                            <h1 className="history-card-text-date">
                                Every other {schedule.date.dayOfTheWeek} 
                            </h1>
                        }
                        {schedule.cadence === 'monthly' && 
                            <h1 className="history-card-text-date">
                                Every {dayParser(schedule.date.weekOfTheMonth)} {schedule.date.dayOfTheWeek} 
                            </h1>
                        }
                    </div>
                </div>
                <div className="schedule-card-total-container">
                    {schedule.cadence === 'single' && 
                        <h2 className="history-card-text-total">
                            {moment(`${schedule.date}T${schedule.time}`).format('M/DD/YY')} at {moment(`${date}T${schedule.time}`).format('hh:mm A')}
                        </h2>
                    }
                    {schedule.cadence === 'weekly' && 
                        <h2 className="history-card-text-total">
                            AT {moment(`${date}T${schedule.time}`).format('h:mm A')}
                        </h2>
                    }
                    {schedule.cadence === 'bi-weekly' && 
                        <h2 className="history-card-text-total">
                            {moment(`${date}T${schedule.time}`).format('hh:mm A')}
                        </h2>
                    }
                    {schedule.cadence === 'monthly' && 
                        <h2 className="history-card-text-total">
                            {moment(`${date}T${schedule.time}`).format('hh:mm A')}
                        </h2>
                    }
                    {schedule?.recipient?.customerName && 
                        <h2 className="schedule-card-text-recipient">
                            {schedule.recipient.customerName.length > 18 
                                ? `${schedule.recipient.customerName.substring(0, 18)}...` 
                                : schedule.recipient.customerName
                            }
                        </h2>
                    }
                </div>
                <div style={{ flexDirection: 'row' }}>
                    <img 
                        onClick={() => setAddToSchedule(true)}
                        src={EditIcon}
                        className="edit-icon"
                        style={{width: 20}}
                    />
                    <img 
                        onClick={deleteSchedule}
                        src={TrashIcon}
                        className="edit-icon"
                        style={{width: 20}}
                    />
                </div>
            </div>
            {addToSchedule && (
                <AddToSchedule 
                    setAddToSchedule={setAddToSchedule}
                    editSchedule={true}
                    editCadence={schedule.cadence}
                    editDate={schedule.date}
                    editTime={schedule.time}
                    editRecipient={schedule.recipient}
                    scheduleId={schedule.id}
                />
            )}
        </div>
    );
    
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchDeleteSchedule: (scheduleId, onSuccess, onError) =>
        dispatch(deleteSchedule(scheduleId, onSuccess, onError))
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleCard)