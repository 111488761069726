import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { getMe } from '../../redux/actions/user'
import { createEstimation } from '../../redux/actions/donation'
import DonateIcon from '../../assets/images/donate_confirm.png'
import CustomButton from '../CustomButton'
import LoadingButton from '../LoadingButton'
import RecipientDropdown from '../RecipientDropdown'
import PickupDropdown from '../PickupDropdown'
import { useNavigate } from "react-router-dom"

import './styles.css'

const CheckoutConfirm = ({ getme, recipients, setConfirm, setDelivery, setEstimate, dispatchEstimation, express, width }) => {
    const [loading, setLoading] = useState(false)
    const [recipient, setRecipient] = useState({})
    const [addresses, setAddresses] = useState([]);
    const [pickupAddress, setPickupAddress] = useState('')
    const [pickupInstructions, setPickupInstructions] = useState('')
   
    const navigate = useNavigate()
    
    useEffect(() => {
        setLoading(true);
        if (getme.multiAddress === true && getme.addresses) {
            const addressesArray = Object.values(getme.addresses);
            setAddresses(addressesArray); // Store the full address objects
        }
        setLoading(false);
    }, [getme.multiAddress, getme.addresses]);

    useEffect(() => {
        if (addresses.length > 0) {
            // Set both address and instructions from the first address object
            setPickupAddress(addresses[0].address);
            setPickupInstructions(addresses[0].pickupInstruction);
        }
    }, [addresses]);

    const getDeliveryEstimation = () => {
        setLoading(true)
        const addressToUse = getme.multiAddress ? pickupAddress : getme.address;
        const instructionToUse = getme.multiAddress ? pickupInstructions : getme.instruction;
        
        dispatchEstimation(
            getme.atCost,
            getme.bag.totals.itemCount,
            getme.bag.totals.totalWeight,
            instructionToUse,
            recipient,
            getme.restaurant,
            addressToUse,
            getme.phone,
            getme.billing.driverTip,
            getme.bag,
            (response) => {
                setDelivery(response); setLoading(false); setEstimate(true); setConfirm(false); navigate('/checkout', { state: { estimate: true, express: express } })
            },
            (error) => { console.log(error); setLoading(false) }
        )
    }

    const taxSavingTotalFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(getme.bag.totals.totalTaxSavings)
    const lbsFormat = new Intl.NumberFormat('us').format(getme.bag.totals.totalWeight)

    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <div className='checkout_confirm'>

            <div className='checkout_header' style={{ borderBottom: 'none' }}>
                {!express === true ?
                    <>
                        <div className='home_info_container'>
                            <div className='donate_info_box'>
                                <p className='donate_number_title'>{taxSavingTotalFormat}</p>
                                <p className='donate_text_subtitle'>Tax deductions</p>
                            </div>
                                <div className='donate_info_box'>
                                    <p className='donate_number_title'>{getme.bag.totals.itemCount}</p>
                                    <p className='donate_text_subtitle'>{getme.bag.totals.itemCount > 1 ? 'Items' : 'Item'}</p>
                                </div>
                            <div className='donate_info_box'>
                                <p className='donate_number_title'>{lbsFormat}</p>
                                <p className='donate_text_subtitle'>Total weight</p>
                            </div>
                        </div>
                        <div className='checkout_main_buttons_container'>
                            {getme.multiAddress === true &&
                                <PickupDropdown
                                    pickupAddressList={addresses}
                                    pickupAddress={pickupAddress}
                                    setPickupDetails={(selectedAddress) => {
                                        setPickupAddress(selectedAddress.address);
                                        setPickupInstructions(selectedAddress.pickupInstruction);
                                    }}
                                />
                            }
                            <RecipientDropdown
                                recipientsList={recipients.data}
                                recipient={recipient}
                                setRecipient={setRecipient}
                                style={{ marginBottom: 10 }}
                            />
                            <LoadingButton
                                isLoading={loading}
                                title='Next Step'
                                onClick={getDeliveryEstimation}
                                color='blue'
                                style={{ marginBottom: 10, marginRight: 10, marginLeft: 10 }}
                            />
                            <CustomButton
                                title='Cancel'
                                onClick={() => { setConfirm(false) }}
                                color='white'
                                style={{ marginBottom: 10, marginRight: 10, marginLeft: 10 }}
                            />
                        </div>
                    </>
                    :
                    <div className='checkout_main_buttons_container_optional'>
                        {getme.multiAddress === true &&
                            <PickupDropdown
                                pickupAddressList={addresses}
                                pickupAddress={pickupAddress}
                                setPickupDetails={(selectedAddress) => {
                                    setPickupAddress(selectedAddress.address);
                                    setPickupInstructions(selectedAddress.pickupInstruction);
                                }}
                            />
                        }
                        <RecipientDropdown
                            recipientsList={recipients.data}
                            recipient={recipient}
                            setRecipient={setRecipient}
                            style={{ marginBottom: 10 }}
                        />
                        <LoadingButton
                            isLoading={loading}
                            title='Next Step'
                            onClick={getDeliveryEstimation}
                            color='blue'
                            style={{ marginBottom: 10, marginRight: 10, marginLeft: 10 }}
                        />
                        <CustomButton
                            isLink={true}
                            title='Cancel'
                            to='/'
                            color='white'
                            style={{ marginBottom: 10, marginRight: 10, marginLeft: 10 }}

                        />
                        <h1 className='donate_express_text'>Donation must be under 300 pounds and fit in the backseat of a sedan.</h1>
                    </div>
                }
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchEstimation: (atCost, itemCount, totalWeight, instruction, recipient, restaurantName, restaurantAddress, restaurantPhoneNumber, driverTip, bag, onSuccess, onError) =>
        dispatch(createEstimation({ atCost, itemCount, totalWeight, instruction, recipient, restaurantName, restaurantAddress, restaurantPhoneNumber, driverTip, bag }, onSuccess, onError)),
})

const mapStateToProps = (state) => ({
    getme: state.getme,
    recipients: state.recipients
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutConfirm)