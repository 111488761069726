import React, { useState } from 'react'
import { connect } from 'react-redux'

import { getMe, updateUser } from '../../redux/actions/user'
import { updateScheduleBag } from '../../redux/actions/schedule'

import { IoCloseSharp } from "react-icons/io5"
import EditIcon from '../../assets/icons/edit.svg'
import CheckIcon from '../../assets/icons/check.svg'
import TrashIcon from '../../assets/icons/bag.svg'
import { NumericFormat } from 'react-number-format'

import './styles.css'

const CheckoutItem = ({ 
    itemType, 
    category, 
    lbs, 
    itemId, 
    taxSaving, 
    cost, 
    fmv,
    scheduledDonation,
    donationId,
    disptachGetMe, 
    dispatchUpdateUser,
    dispatchUpdateScheduleBag,
    getme 
}) => {
    const [edit, setEdit] = useState(false)
    const [editLbs, setEditLbs] = useState('')
    const [fullCart, setFullCart] = useState(false)

    const taxSavingTotalFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(lbs * taxSaving)
    const lbsFormat = new Intl.NumberFormat('us').format(lbs)
    // const checkBagLbs = (getme.bag.totals.totalWeight - lbs) + Number(editLbs)

    // const updateBag = (action) => {
    //     const newlbs = editLbs ? editLbs : lbs

    //     if (checkBagLbs > 300 && action === 'add') {
    //         setFullCart(true)
    //     } else {
    //         const item = {
    //             itemId: itemId,
    //             itemType: itemType || category,
    //             cost: cost,
    //             fmv: fmv,
    //             taxSaving: taxSaving,
    //             lbs: Number(newlbs),
    //         }

    //         dispatchUpdateUser(
    //             action,
    //             item,
    //             getme.id,
    //             () => { disptachGetMe(); setEdit(false) },
    //             (error) => console.log(error)
    //         )
    //     }
    // }

    let findBag
    let bag
    let currentLbs
    let checkBagLbs
    if (scheduledDonation) {
        findBag = getme.donations.find(donation => donation.id === donationId).bag
        bag = findBag.items.find(item => item.itemId === itemId)
        currentLbs  = !bag?.lbs ? 0 : bag?.lbs
        checkBagLbs = (findBag.totals.totalWeight - currentLbs) + Number(editLbs)
        // console.log('currentLbs', currentLbs, 'checkBagLbs', checkBagLbs)
    } else {
        bag = getme.bag.items.find(item => item.itemId === itemId)
        currentLbs  = !bag?.lbs ? 0 : bag?.lbs
        checkBagLbs = (getme.bag.totals.totalWeight - currentLbs) + Number(editLbs)
    } 

    const updateBag = (action) => {
        const newlbs = editLbs ? editLbs : lbs

        if (checkBagLbs > 300 && action === 'add') {
            alert(
                'Donation must be 300lbs or less',
                'If your donation is bigger, please split into a second delivery.'
            )
        } else {
            const item = {
                itemId: itemId,
                itemType: itemType || category,
                cost: cost,
                fmv: fmv,
                taxSaving: taxSaving,
                lbs: Number(newlbs),
            }
            if (scheduledDonation)  {
                dispatchUpdateScheduleBag(
                    action, 
                    item, 
                    findBag, 
                    donationId, 
                    () => disptachGetMe(), 
                    (error) => console.log(error)
                );
                setEdit(false)
            } else {
                dispatchUpdateUser(
                    action,
                    item,
                    getme.id,
                    () => { disptachGetMe(); setEdit(false) },
                    (error) => console.log(error)
                )
            }
        }
    }


    return (
        <div className='checkout_item'>
            {fullCart && (
                <div className='bag_item_modal'>
                    <div className='bag_item_modal_container'>
                        <IoCloseSharp size={35} className='checkout_estimate_modal_icon' onClick={() => setFullCart(false)} />
                        <p className='checkout_estimate_modal_title'>Limit Reached</p>
                        <p className='bag_item_modal_subtitle'>Donation must be under 300 pounds and fit in the backseat of a sedan.</p>
                        <p className='bag_item_modal_subtitle'>Please finish the current donation before adding more food items.</p>
                        <button className='bag_modal_button' onClick={() => { setEdit(false); setFullCart(false) }}>Conclude Donation</button>
                    </div>
                </div>
            )}

            <div className='checkout_item_section_one'>
                <p className='checkout_item_name'>
                    {itemType ? itemType.toUpperCase() : (category ? category.toUpperCase() : '')}
                </p>            
            </div>
            <div className='checkout_item_section'>
                {edit ?
                    <>
                        <div className="checkout_item_section_two">
                            <NumericFormat
                                className='checkout_item_input'
                                onValueChange={(e) => setEditLbs(Number(e.value))}
                                placeholder={lbs}
                                valueIsNumericString={true}
                                type="text"
                                fixedDecimalScale={true}
                                maxLength={11}
                                allowNegative={false}
                            />
                            <p className='checkout_item_name'>lbs</p>
                            <img src={CheckIcon}
                                onClick={() => updateBag('add')}
                                className='checkout_item_icon'
                            />
                        </div>
                    </>
                    : <>
                        <div className="checkout_item_section_two">
                            <p className='checkout_item_name'>{lbsFormat} lbs</p>
                            <img src={EditIcon}
                                onClick={() => setEdit(true)}
                                className='checkout_item_icon'
                            />
                        </div>

                    </>
                }

            </div>
            <div className='checkout_item_section'>
                <div className="checkout_item_section_icon">
                    <p className='checkout_item_value'>{taxSavingTotalFormat}</p>
                    <img src={TrashIcon}
                        className='checkout_item_icon'
                        onClick={() => { updateBag('remove') }}
                    />
                </div>

            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateUser: (action, foodItem, userId, onSuccess, onError) =>
        dispatch(updateUser({ action, foodItem }, userId, onSuccess, onError)),
    dispatchUpdateScheduleBag: (action, foodItem, currentBag, donationId, onSuccess, onError) =>
        dispatch(updateScheduleBag({ action, foodItem, currentBag, donationId }, onSuccess, onError)),
    disptachGetMe: () => dispatch(getMe()),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutItem)