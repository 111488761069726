import React, { useState, useRef, useEffect, useMemo, forwardRef } from 'react'
import { IoCloseSharp } from "react-icons/io5"
import { connect } from 'react-redux'
import { updateSchedule } from '../../redux/actions/schedule'
import { getRecipientsAvailable } from '../../redux/actions/delivery'
import { getMe } from '../../redux/actions/user'
import { IoChevronDownOutline } from "react-icons/io5"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'
import LoadingButton from '../LoadingButton'

import './styles.css'

const CustomInput = forwardRef(({ value, onClick, text }, ref) => (
    <button className="schedule_selector" onClick={onClick} ref={ref}>
        {value || text}
    </button>
))

const EditSchedule = ({ getme, disptachGetMe, setEditSchedule, schedule, dispatchUpdateSchedule, dispatchAvailableRecipients }) => {
    const [cadence, setCadence] = useState('')
    const [weekday, setWeekday] = useState('')
    const [monthlyday, setMonthlyday] = useState('')
    const [singleday, setSingleday] = useState('')
    const [time, setTime] = useState('')
    const [recipient, setRecipient] = useState({})
    const [recipientsAvailable, setRecipientsAvailable] = useState([])
    const [loading, setLoading] = useState(false)

    const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    const biweeklyOptions = ['1st and 2nd', '1st and 3rd', '1st and 4th', '2nd and 3rd', '2nd and 4th', '3rd and 4th']
    const monthlydays = ['1st', '2nd', '3rd', '4th']
    const timeDate = new Date

    function addDays(days) {
        var result = new Date(schedule.date);
        result.setDate(result.getDate() + days)
        return result
    }

    useMemo(() => {
        setCadence(schedule.cadence)

        setWeekday(schedule.date)
        setMonthlyday(schedule.date)
        setSingleday(Date.parse(schedule.date))
        setTime(Date.parse(`${timeDate.toLocaleDateString("en-US")}, ${schedule.time}`))
        setRecipient(schedule?.recipient)

        if (schedule.cadence === 'monthly' || schedule.cadence === 'bi-weekly') {
            setWeekday(schedule.date.dayOfTheWeek)
            setMonthlyday(schedule.date.weekOfTheMonth)
        }
    }, [])

    let menuRef = useRef()

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef.current.contains(event.target)) {
                setEditSchedule(false)
            }
        }

        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const handleUpdateSchedule = () => {
        setLoading(true)

        let date

        switch (cadence) {
            case 'single':
                const rowDate = moment(singleday)
                date = rowDate.format("YYYY-MM-DD")
                break;
            case 'weekly':
                date = weekday
                break
            case 'bi-weekly':
                date = { weekOfTheMonth: monthlyday, dayOfTheWeek: weekday }
                break
            case 'monthly':
                date = { weekOfTheMonth: Number(monthlyday), dayOfTheWeek: weekday }
                break
            default:
                break;
        }

        if (!date || !time) {
            console.log('Please select date and time')
            setLoading(false)
            return
        }

        function isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }

        dispatchUpdateSchedule(
            schedule.id,
            cadence,
            date,
            moment(time).format().slice(11, 19),
            getme.atCost,
            getme.instruction,
            isJsonString(recipient) ? JSON.parse(recipient) : recipient,
            getme.restaurant,
            getme.address,
            getme.phone,
            getme.billing.driverTip,
            getme.freeTrial,
            getme.id,
            getme.billing,
            () => { disptachGetMe(); setEditSchedule(false); setLoading(false) },
            (error) => { console.log(error); setLoading(false) }
        )
    }

    const handleAvailableRecipients = (day, time) => {

        let date
        time = moment(time).format("hh:mm:ss")

        switch (cadence) {
            case 'single':
                date = moment(day).format("YYYY-MM-DD")
                break;
            case 'weekly':
                date = day
                break
            case 'bi-weekly':
                date = day
                break
            case 'monthly':
                date = { dayOfTheWeek: day }
                break
        }

        dispatchAvailableRecipients(
            getme.recipients,
            "schedule",
            cadence,
            date,
            time,
            (response) => { setRecipientsAvailable(response.data) },
            (error) => { console.log(error); setRecipientsAvailable([]) }
        )
    }

    useEffect(() => handleAvailableRecipients(singleday || weekday, `${moment(timeDate).format('YYYY-MM-DD')}T${schedule.time}`),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    return (
        <div className='group_item'>
            <div className='group_item_container' ref={menuRef}>
                <IoCloseSharp className='group_icon_close' onClick={() => setEditSchedule(false)} />
                <p className='group_item_main_title'>Update Schedule</p>

                <div className='schedule_container'>
                    {
                        cadence === 'single' ? (
                            <div className='schedule_selector_container' style={{ zIndex: 10 }}>
                                <DatePicker selected={singleday}
                                    includeDates={[singleday, addDays(1), addDays(2), addDays(3), addDays(4), addDays(5), addDays(6), addDays(7)]}
                                    customInput={<CustomInput />}
                                    onChange={(date) => { setSingleday(date); handleAvailableRecipients(date, time) }} />
                            </div>
                        ) : null
                    }
                    {
                        cadence === 'weekly' ? (
                            <>
                                <div className='schedule_selector_container'>
                                    <select className='schedule_selector' onChange={(e) => { setWeekday(e.target.value.toLocaleLowerCase()); handleAvailableRecipients(e.target.value.toLocaleLowerCase(), time) }}>
                                        <option value="" disabled defaultValue={weekday} hidden>{weekday}</option>
                                        {weekdays.map((item) =>
                                            <option key={item} value={item.toLocaleLowerCase()}>{item}</option>
                                        )}
                                    </select>
                                </div>
                            </>
                        ) : null
                    }
                    {
                        cadence === 'bi-weekly' ? (
                            <>
                                <div className='schedule_selector_container'>
                                    <select className='schedule_selector' onChange={(e) => setMonthlyday(e.target.value)}>
                                        <option value={monthlyday} hidden>{monthlyday}</option>
                                        {biweeklyOptions.map((item) =>
                                            <option key={item} value={[Number(item.slice(0, 1)), Number(item.slice(8, 9))]}>{item}</option>
                                        )}
                                    </select>
                                </div>
                                <div className='schedule_selector_container'>
                                    <select className='schedule_selector' onChange={(e) => { setWeekday(e.target.value.toLocaleLowerCase()); handleAvailableRecipients(e.target.value.toLocaleLowerCase(), time) }}>
                                        <option value={weekday} hidden>{weekday}</option>
                                        {weekdays.map((item) =>
                                            <option key={item} value={item.toLocaleLowerCase()}>{item}</option>
                                        )}
                                    </select>
                                </div>
                            </>
                        ) : null
                    }
                    {
                        cadence === 'monthly' ? (
                            <>
                                <div className='schedule_selector_container'>
                                    <select className='schedule_selector' onChange={(e) => setMonthlyday(e.target.value)}>
                                        <option value={monthlyday} hidden>{monthlyday}</option>
                                        {monthlydays.map((item) =>
                                            <option key={item} value={item.slice(0, 1)}>{item}</option>
                                        )}
                                    </select>
                                </div>

                                <div className='schedule_selector_container'>
                                    <select className='schedule_selector' onChange={(e) => {setWeekday(e.target.value.toLocaleLowerCase()); handleAvailableRecipients(e.target.value.toLocaleLowerCase(), time)}}>
                                        <option value={weekday} hidden>{weekday}</option>
                                        {weekdays.map((item) =>
                                            <option key={item} value={item.toLocaleLowerCase()}>{item}</option>
                                        )}
                                    </select>
                                </div>
                            </>
                        ) : null
                    }
                    <div className='schedule_selector_container' style={{ zIndex: 10 }}>
                        <DatePicker
                            selected={time}
                            onChange={(date) => { setTime(date); handleAvailableRecipients(singleday || weekday, date) }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeCaption="Time"
                            dateFormat="h:mm aa"
                            customInput={<CustomInput text="Select Time" />}
                        />
                    </div>
                    <div className='schedule_selector_container'>
                        <IoChevronDownOutline className='schedule_selector_icon' />
                        <select className='schedule_selector' onChange={(e) => setRecipient(e.target.value)} placeholder={cadence}>
                            <option value="" disabled defaultValue={recipient?.customerName} hidden>{recipient?.customerName}</option>
                            {recipientsAvailable.map((item) =>
                                <option key={item.id} value={JSON.stringify(item)}>{item.customerName}</option>
                            )}
                        </select>
                    </div>
                    <LoadingButton
                        onClick={handleUpdateSchedule}
                        title="Uodate Schedule"
                        color="gradient-blue"
                        style={{ width: 300, fontSize: '1.1rem' }}
                        isLoading={loading}
                    />
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateSchedule: (scheduleId, cadence, date, time, atCost, instruction, recipient, restaurantName, restaurantAddress, restaurantPhoneNumber, driverTip, freeTrial, ownerId, paymentDetails, onSuccess, onError) =>
        dispatch(updateSchedule(scheduleId, { cadence, date, time, atCost, instruction, recipient, restaurantName, restaurantAddress, restaurantPhoneNumber, driverTip, freeTrial, ownerId, paymentDetails }, onSuccess, onError)),
    dispatchAvailableRecipients: (recipients, type, cadence, date, period, onSuccess, onError) =>
        dispatch(getRecipientsAvailable({ recipients, type, cadence, date, period }, onSuccess, onError)),
    disptachGetMe: () => dispatch(getMe()),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(EditSchedule)