import React, { useState, useRef, useEffect, useMemo } from 'react'
import { IoCloseSharp } from "react-icons/io5"
import { connect } from 'react-redux'
import { updateFoodGroup } from '../../redux/actions/foodGroup'
import { getMe } from '../../redux/actions/user'
import { Ellipsis } from 'react-css-spinners'
import GroupForm from '../GroupForm'
import { IoAddCircleOutline, IoRemoveCircleOutline, IoChevronDownOutline } from "react-icons/io5";

import './styles.css'

const EditFoodGroup = ({ dispatchUpdateFoodGroup, disptachGetMe, setEditGroup, category, ownerId, items, itemId }) => {
    const [editCategory, setEditCategory] = useState([])
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false)
    const [message, setMessage] = useState('')
    const [item, setItem] = useState(items.length)

    const [type1, setType1] = useState('')
    const [cost1, setCost1] = useState('')
    const [fmv1, setFmv1] = useState('')
    const [weight1, setWeight1] = useState('')

    const [type2, setType2] = useState('')
    const [cost2, setCost2] = useState('')
    const [fmv2, setFmv2] = useState('')
    const [weight2, setWeight2] = useState('')

    const [type3, setType3] = useState('')
    const [cost3, setCost3] = useState('')
    const [fmv3, setFmv3] = useState('')
    const [weight3, setWeight3] = useState('')

    const [type4, setType4] = useState('')
    const [cost4, setCost4] = useState('')
    const [fmv4, setFmv4] = useState('')
    const [weight4, setWeight4] = useState('')

    const [type5, setType5] = useState('')
    const [cost5, setCost5] = useState('')
    const [fmv5, setFmv5] = useState('')
    const [weight5, setWeight5] = useState('')

    useMemo(() => {
        setEditCategory(category)

        setType1(items[0]?.type)
        setCost1(items[0]?.cost)
        setFmv1(items[0]?.fmv)
        setWeight1(items[0]?.weight)

        setType2(items[1]?.type)
        setCost2(items[1]?.cost)
        setFmv2(items[1]?.fmv)
        setWeight2(items[1]?.weight)

        setType3(items[2]?.type)
        setCost3(items[2]?.cost)
        setFmv3(items[2]?.fmv)
        setWeight3(items[2]?.weight)

        setType4(items[3]?.type)
        setCost4(items[3]?.cost)
        setFmv4(items[3]?.fmv)
        setWeight4(items[3]?.weight)

        setType5(items[4]?.type)
        setCost5(items[4]?.cost)
        setFmv5(items[4]?.fmv)
        setWeight5(items[4]?.weight)
    }, [])

    let menuRef = useRef()

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef.current.contains(event.target)) {
                setEditGroup(false)
            }
        };

        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    const group = [
        {
            type: type1,
            cost: cost1,
            fmv: fmv1,
            weight: weight1
        },
        {
            type: type2,
            cost: cost2,
            fmv: fmv2,
            weight: weight2
        },
        {
            type: type3,
            cost: cost3,
            fmv: fmv3,
            weight: weight3
        },
        {
            type: type4,
            cost: cost4,
            fmv: fmv4,
            weight: weight4
        },
        {
            type: type5,
            cost: cost5,
            fmv: fmv5,
            weight: weight5
        }
    ]

    const handleAddItem = () => {

        switch (item) {
            case 1:
                setItem(2)
                break
            case 2:
                setItem(3)
                break;
            case 3:
                setItem(4)
                break
            case 4:
                setItem(5)
            default:
                break
        }
    }

    const handleRemoveItem = () => {

        switch (item) {
            case 1:
                setItem(1)
                break
            case 2:
                setItem(1)
                setType2('')
                setCost2('')
                setFmv2('')
                setWeight2('')
                break;
            case 3:
                setItem(2)
                setType3('')
                setCost3('')
                setFmv3('')
                setWeight3('')
                break
            case 4:
                setItem(3)
                setType4('')
                setCost4('')
                setFmv4('')
                setWeight4('')
                break
            case 5:
                setItem(4)
                setType5('')
                setCost5('')
                setFmv5('')
                setWeight5('')
            default:
                break
        }
    }

    const handleCreateGroup = () => {
        setLoading(true)
        let items = []

        for (const item of group) {
            if (item.type) {
                items.push(item)
            }
        }

        if (items.length < 3) {
            setAlert(true)
            setMessage('Insert at least 3 items to create a new group')
            setLoading(false)
            return
        }

        for (const item of items) {
            if (!item.type || !item.cost || !item.weight) {
                setAlert(true)
                setMessage('Insert missing fields, name, cost, or weight to create a new group.')
                setLoading(false)
                return
            }
        }

        dispatchUpdateFoodGroup(
            ownerId,
            editCategory,
            items,
            itemId,
            () => {
                disptachGetMe()
                setLoading(false)
                setEditGroup(false)
            },
            (error) => {setAlert(true); setMessage(error); setLoading(false)}
        )
    }

    const categories = [
        'Mixed Produce',
        'Veggies',
        'Fruit',
        'Prepared Foods',
        'Mixed Proteins',
        'Meat',
        'Seafood',
        'Eggs',
        'Plant-based Proteins',
        'Baked Goods',
        'Sweets',
        'Fats and Oils',
        'Condiments and Sauces',
        'Baby Foods',
        'Shelf Stable',
        'Beverages',
        'Dairy']

    return (
        <div className='group_item'>
            <div className='group_item_container' ref={menuRef}>
                <IoCloseSharp className='group_icon_close' onClick={() => setEditGroup(false)} />
                <p className='group_item_main_title'>Update Group</p>
                <div>
                    <div className='history_report_select_container'>
                        <IoChevronDownOutline className='group_food_category_selector_icon' />
                        <select className='group_food_category_selector' onChange={(e) => setEditCategory(e.target.value)}>
                            <option value={editCategory}>{editCategory}</option>
                            {categories.map((item) =>
                                <option key={item} value={item}>{item}</option>
                            )}
                        </select>
                    </div>
                </div>

                <GroupForm
                    item="1"
                    setType={setType1}
                    setCost={setCost1}
                    setFmv={setFmv1}
                    setWeight={setWeight1}
                    type={type1}
                    cost={cost1}
                    fmv={fmv1}
                    weight={weight1}
                />
                {(item === 2 || item > 2) && (
                    <GroupForm
                        item="2"
                        setType={setType2}
                        setCost={setCost2}
                        setFmv={setFmv2}
                        setWeight={setWeight2}
                        type={type2}
                        cost={cost2}
                        fmv={fmv2}
                        weight={weight2}
                    />
                )}

                {(item === 3 || item > 3) && (
                    <GroupForm
                        item="3"
                        setType={setType3}
                        setCost={setCost3}
                        setFmv={setFmv3}
                        setWeight={setWeight3}
                        type={type3}
                        cost={cost3}
                        fmv={fmv3}
                        weight={weight3}
                    />
                )}

                {(item === 4 || item > 4) && (
                    <GroupForm
                        item="4"
                        setType={setType4}
                        setCost={setCost4}
                        setFmv={setFmv4}
                        setWeight={setWeight4}
                        type={type4}
                        cost={cost4}
                        fmv={fmv4}
                        weight={weight4}
                    />
                )}

                {item === 5 && (
                    <GroupForm
                        item="5"
                        setType={setType5}
                        setCost={setCost5}
                        setFmv={setFmv5}
                        setWeight={setWeight5}
                        type={type5}
                        cost={cost5}
                        fmv={fmv5}
                        weight={weight5}
                    />
                )}

                <div className='group_item_icons_add_remove'>
                    <IoAddCircleOutline className='group_item_add_icon' onClick={handleAddItem} />
                    <IoRemoveCircleOutline className='group_item_add_icon' onClick={handleRemoveItem} />
                </div>

                <div style={{ marginTop: 15 }}>
                    <button className="group_item_button" disabled={loading ? true : false} onClick={handleCreateGroup}>
                        {
                            !loading ? 'Update Group' : <span> <Ellipsis color="#FFF" size={38} style={{ marginTop: 3 }} /></span>
                        }
                    </button>
                </div>
                {alert && (<p className='add_item_alert' style={{marginTop: 8}}>{message}</p>)}
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchUpdateFoodGroup: (ownerId, category, items, itemId, onSuccess, onError) =>
        dispatch(updateFoodGroup({ ownerId, category, items }, itemId, onSuccess, onError)),
    disptachGetMe: () => dispatch(getMe()),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(EditFoodGroup)