import React, { useState, useEffect, useRef } from 'react';
import { IoClose, IoChevronDownOutline } from 'react-icons/io5'

import './styles.css'

const PickupDropdown = ({ pickupAddress, setPickupDetails, pickupAddressList }) => {
    const [open, setOpen] = useState(false)
    const [width, setWidth] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setWidth(ref.current.clientWidth)
    })

    let menuRef = useRef()

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef.current.contains(event.target)) {
                setOpen(false)
            }
        };

        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    // useEffect(() => {
    //     // Convert object values to an array and select the first one
    //     const firstAddress = Object.values(pickupAddressList)[0].address;
    //     console.log('pickup', firstAddress);
    //     if (firstAddress) {
    //         setPickupAddress(firstAddress);
    //     }
    // }, [pickupAddressList]);

    return (
        <div ref={menuRef} className={`pickup_select ${open ? 'open' : ''}`}>
            <div className="pickup_select_container" onClick={() => setOpen(!open)} ref={ref}>
                <p className={pickupAddress.length > 17 ? 'pickup_option_title_lower' : 'pickup_option_title'}>{pickupAddress || 'CHOOSE PICKUP ADDRESS'}</p>
                <div className='pickup_icon_containers'>
                    {open ?
                        <IoClose className='pickup_select_close_icon' /> :
                        <IoChevronDownOutline className='pickup_select_chevron_icon' />}
                </div>
            </div>
            {open && (
                <div className="pickup_select_container_option">
                    {pickupAddressList.map((value, index) => (
                        <button key={index} className="pickup_option_button" onClick={() => { 
                            setPickupDetails(value); // Pass the entire address object
                            setOpen(!open); 
                        }}>
                            {String(value.address).slice(0, 32)}
                        </button>
                    ))}
                </div>
            )}
        </div>

    )
}

export default PickupDropdown