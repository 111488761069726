import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import CheckoutItem from '../../components/CheckoutItem'
import { getMe } from '../../redux/actions/user'
import { getRecipientsAvailable } from '../../redux/actions/delivery'
import CheckoutConfirm from '../../components/CheckoutConfirm'
import CheckoutEstimate from '../../components/CheckoutEstimate'
import CustomButton from '../../components/CustomButton'
import LoadingButton from '../../components/LoadingButton'
import ModalSubsribe from '../../components/ModalSubscribe'
import BoxIcon from '../../assets/icons/box.svg'
import CarPlusIcon from '../../assets/icons/car_plus.svg'

import './styles.css'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    }
}

const Checkout = ({ getme, disptachGetMe, dispatchGetRecipients }) => {
    const [confirm, setConfirm] = useState(false)
    const [estimate, setEstimate] = useState(false)
    const [delivery, setDelivery] = useState({})
    const [link, setLink] = useState('')
    const [trackingLink, setTrackingLink] = useState(false)
    const [loading, setLoading] = useState(false)
    const [serverError, setServerError] = useState('')
    const { state } = useLocation()
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    const taxDeductionFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(getme.bag.totals.totalTaxSavings)
    const lbsFormat = new Intl.NumberFormat('us').format(getme.bag.totals.totalWeight)

    const getRecipients = () => {
        setLoading(true)
        dispatchGetRecipients(
            getme.recipients,
            (response) => { setLoading(false); setConfirm(true); },
            (error) => { console.log(error); setLoading(false); setServerError(error.error) }
        )
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <div className='checkout'>
            {
                windowDimensions.width < 800 && (
                    <div className='header_page_title_container'>
                        <img src={estimate ? CarPlusIcon : BoxIcon} className='header_page_title_icon_box' />
                        <p className='header_page_title'>{
                            state?.express ? 'Express Donate' :
                                estimate ? 'Schedule Pickup' :
                                    'Donation Information'}</p>
                    </div>
                )
            }
            {!getme.freeTrial && !getme.billing.subscription.active && !getme.billing.subscription.freeSubscription && (
                <ModalSubsribe />
            )}
            <div className='page_fix_container'>

                {(!confirm && !state?.express) && !estimate && !trackingLink ?
                    <>
                        <div className='checkout_header'>
                            <div className='home_info_container'>
                                <div className='donate_info_box'>
                                    <p className='donate_number_title'>{taxDeductionFormat}</p>
                                    <p className='donate_text_subtitle'>Tax deductions</p>
                                </div>
                                <div className='donate_info_box'>
                                    <p className='donate_number_title'>{getme.bag.totals.itemCount}</p>
                                    <p className='donate_text_subtitle'>Items</p>
                                </div>
                                <div className='donate_info_box'>
                                    <p className='donate_number_title'>{lbsFormat}</p>
                                    <p className='donate_text_subtitle'>Total weight</p>
                                </div>
                            </div>
                            {getme.bag.totals.itemCount > 0 && (
                                <div className='checkout_main_buttons_container'>
                                    <LoadingButton
                                        isLoading={loading}
                                        onClick={getRecipients}
                                        title='Looks Good!'
                                        color='blue'
                                        style={{ marginBottom: 10, marginRight: 10, marginLeft: 10 }} />
                                    <CustomButton isLink={true} to="/donate" title='Add Items' color='green' style={{ marginBottom: 10, marginRight: 10, marginLeft: 10 }} />
                                    {serverError ? <p className="login-error">{serverError}</p> : null}
                                </div>
                            )}
                            {getme.bag.totals.itemCount === 0 && (
                                <>
                                    <CustomButton isLink={true} to="/donate" title='Add Items' color='blue' style={{ marginBottom: 10 }} />
                                    <CustomButton
                                        isLink={true}
                                        to="/checkout"
                                        state={{ express: true }}
                                        title='Express Donate'
                                        color='green'
                                    />
                                </>
                            )}

                        </div>
                        <div className='checkout_items_container'>
                            {getme.bag.items.map((item) =>
                                <CheckoutItem
                                    key={item.itemId}
                                    itemId={item.itemId}
                                    itemType={item.itemType}
                                    category={item.category}
                                    cost={item.cost}
                                    fmv={item.fmv}
                                    lbs={item.lbs}
                                    taxSaving={item.taxSaving}
                                />)}
                        </div>
                    </>
                    : (confirm || state?.express) && !estimate ?
                        <CheckoutConfirm
                            setConfirm={setConfirm}
                            setEstimate={setEstimate}
                            setDelivery={setDelivery}
                            express={state?.express}
                            width={windowDimensions.width}
                        />
                        : estimate && !trackingLink ?
                            <CheckoutEstimate
                                setEstimate={setEstimate}
                                setConfirm={setConfirm}
                                delivery={delivery}
                                express={state?.express}
                                setTrackingLink={setTrackingLink}
                                setLink={setLink}
                                width={windowDimensions.width}
                            />
                            : null}
            </div>
        </div>
    )
}
const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchGetRecipients: (recipients, onSuccess, onError) =>
        dispatch(getRecipientsAvailable({ recipients }, onSuccess, onError)),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(Checkout)
