import * as constants from '../constants'

export const getDeliveryStatus = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/webhook/shipday-status',
        data
    },
})

export const getRecipientsAvailable = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/recipient/available',
        data,
        success: (response) => recipientsAvailable(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

const recipientsAvailable = (data) => ({
    type: constants.RECIPIENTS_AVAILABLE,
    payload: data,
})
