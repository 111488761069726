import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getMe } from '../../redux/actions/user'
import { createReportOne, createReportTwo } from '../../redux/actions/reports'
import HistoryCard from '../../components/HistoryCard'
import ModalSubsribe from '../../components/ModalSubscribe'
import * as XLSX from 'xlsx'
import { IoChevronDownOutline } from 'react-icons/io5'
import JSZip from 'jszip'
import saveAs from 'file-saver'
import axios from 'axios'
import { Ellipsis } from 'react-css-spinners'
import HistoryIcon from '../../assets/icons/history.svg'
import { IoCloseSharp } from "react-icons/io5"

import './styles.css'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    }
}

const History = ({ getme, disptachGetMe, dispatchCreateReportOne, dispatchCreateReportTwo }) => {
    const [reportType, setReportType] = useState('')
    const [fiscalYear, setFiscalYear] = useState('')
    const [openReport, setOpenReport] = useState(false)
    const [alert, setAlert] = useState(false)
    const [loading, setLoading] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    function download(data, reportName) {
        const worksheet = XLSX.utils.json_to_sheet(data)
        const worbook = {
            Sheets: {
                'data': worksheet
            },
            SheetNames: ['data']
        }
        const excelBuffer = XLSX.write(worbook, { bookType: 'xlsx', type: 'array' })
        saveAsExcel(excelBuffer, reportName)
    }

    function saveAsExcel(buffer, reportName) {
        const data = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' })
        saveAs(data, reportName + new Date().getTime())
    }

    const handleReports = () => {
        if (reportType === 'one' && fiscalYear) {
            dispatchCreateReportOne(
                getme.id,
                fiscalYear,
                (response) => {
                    download(response.data, 'Summary_Report_');
                    setAlert(false);
                    setReportType(''); setFiscalYear(''); setOpenReport(false)
                },
                () => { setAlert(false) }
            )
        } else if (reportType === 'two' && fiscalYear) {
            dispatchCreateReportTwo(
                getme.id,
                fiscalYear,
                (response) => {
                    download(response.data, 'Full_History_');
                    setAlert(false);
                    setReportType(''); setFiscalYear(''); setOpenReport(false)
                },
                () => { setAlert(false) }
            )
        } else {
            setAlert(true)
        }
    }

    const years = ['2022', '2023']

    const dowloadAllreceipts = async () => {
        setLoading(true)
        for (const year of years) {
            const donations = getme.donations.filter(donation => donation.createdAt.includes(year))
            const zip = new JSZip()

            for (const donation of donations) {
                axios.defaults.headers.common['Authorization'] = ''
                await axios.get(donation.receiptUrl, { responseType: "blob" }).then((resp) => {
                    zip.file(`receipt_${donation.id}.pdf`, resp.data)
                })
            }

            zip.generateAsync({ type: "blob" }).then(function (content) {
                saveAs(content, `receipts_${year}.zip`)
            })
        }
        setLoading(false)
    }

    return (
        <div className='history'>
            {openReport && (
                <div className='history_report_modal'>
                    <div className='history_report_modal_box'>
                        <IoCloseSharp size={35} className='subscribe_modal_icon_close' onClick={() => setOpenReport(false)} />
                        <p className='subscribe_modal_title'>Report Details </p>
                        <div className='history_report_select_container'>
                            <IoChevronDownOutline className='history_report_select_icon' />
                            <select className='history_report_select' onChange={(e) => setReportType(e.target.value)}>
                                <option value="">Report Type</option>
                                <option value="one">Summary Report</option>
                                <option value="two">Full History</option>
                            </select>
                        </div>
                        <div className='history_report_select_container'>
                            <IoChevronDownOutline className='history_report_select_icon' />
                            <select className='history_report_select' onChange={(e) => setFiscalYear(e.target.value)}>
                                <option value="">Fiscal Year</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                            </select>
                        </div>
                        <button className='history_report_button' onClick={handleReports}>Get Report</button>
                        {alert ? <p className='add_item_alert' style={{ textAlign: 'center', marginTop: 10 }}>Please select the report type and year</p> : null}

                    </div>
                </div>
            )}
            {windowDimensions.width < 800 &&(
                <div className='header_page_title_container'>
                    <img src={HistoryIcon} className='header_page_title_icon_history' />
                    <p className='header_page_title'>Donation history</p>
                </div>
            )}
            {!getme.freeTrial && !getme.billing.subscription.active && !getme.billing.subscription.freeSubscription && (
                <ModalSubsribe />
            )}
            <div className='history_header'>
                <div className='history_report_container'>
                    <button className='history_report_button' onClick={() => setOpenReport(true)}>Get Report</button>
                    <button className='history_report_button_green' disabled={loading ? true : false} onClick={dowloadAllreceipts}>
                        {
                            !loading ? 'Download All Receipts' : <span> <Ellipsis color="#FFF" size={38} style={{ marginTop: 3 }} /></span>
                        }
                    </button>
                </div>
            </div>
            <div className='history_card_container'>
                {getme?.donations.map((item) => item.status !== 'scheduled' && (
                    <HistoryCard
                        key={item.id}
                        createdAt={item.createdAt}
                        bag={item?.bag}
                        deliveryEstimate={item?.deliveryEstimate}
                        receiptUrl={item?.receiptUrl}
                        id={item.id}
                        status={item.status}
                    />)).reverse()}
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchCreateReportOne: (ownerId, fiscalYear, onSuccess, onError) =>
        dispatch(createReportOne({ ownerId, fiscalYear }, onSuccess, onError)),
    dispatchCreateReportTwo: (ownerId, fiscalYear, onSuccess, onError) =>
        dispatch(createReportTwo({ ownerId, fiscalYear }, onSuccess, onError)),
})


const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
