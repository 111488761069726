import React, { useState, useEffect, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal';
import styled from '@emotion/styled';
import moment from 'moment'

import { getMe } from '../../redux/actions/user'
import { updateScheduleBag } from '../../redux/actions/schedule'
import { cancelDonation } from '../../redux/actions/donation'

import Donate from '../../pages/Donate'

import CustomButton from '../CustomButton';
import CheckoutItem from '../CheckoutItem';

import PlusIcon from '../../assets/icons/plus.svg'
import MinusIcon from '../../assets/icons/minus.svg'
import { IoCloseSharp } from "react-icons/io5"
import { FaExternalLinkAlt } from "react-icons/fa"

import './styles.css'

const StyledModal = styled(Modal)`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    max-height: 85%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: white;
    border-radius: 6px;
    border: 2px solid #f9f9f9;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    
    @media (min-width: 851px) {
        left: calc(50% + 138px);  // Adjust the 100px value to half your sidebar width
        top: calc(50% + 40px);  // Adjust the 30px value to half your navbar height
    }

    @media (max-width: 850px) {
        max-height: 75%;
        width: 95%;
    }
`;

const Background = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(2px);
    
    @media (min-width: 851px) {
        left: 200px;  // Adjust the 200px value to your sidebar width
        top: 60px;  // Adjust the 60px value to your navbar height
    }
`;
const CloseIconContainer = styled.div`
    position: fixed;
    right: 50px;
    font-size: 34px;
    background-color: white;
    border-radius: 17px;
    border: 1px solid #red;
    cursor: pointer;
`;

const UpcomingDonationCard = ({ 
    getme, 
    disptachGetMe, 
    dispatchUpdateScheduleBag, 
    dispatchCancelDonation, 
    donation 
}) => {
    const [loading, setLoading] = useState(false)
    const [addItem, setAddItem] = useState(false)
    const [selected, setSelected] = useState(false)
    const modalNodeRef = useRef(null);
    const modalRef = React.createRef();

    useEffect(() => {
        console.log(modalRef.current);
    }, []);

    useEffect(() => {
        disptachGetMe();
        console.log(donation)
    }, []);
    
    const taxSavingTotalFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(donation?.bag?.totals?.totalTaxSavings || 0);
    const lbsFormat = new Intl.NumberFormat('us').format(donation?.bag?.totals?.totalWeight || 0);

    // const handleEditDonation = (action, item) => {
    //     item["lbs"] = Number(lbs);
    //     dispatchUpdateScheduleBag(action, item, donation?.bag, donation.id, () => disptachGetMe(), (error) => console.log(error));
    // };

    const handleCancelDonation = () => {
        setLoading(true)
        dispatchCancelDonation(
            donation,
            () => {
                disptachGetMe()
                setLoading(false)
            },
            (error) => {
                console.log(error)
                alert('There was an error.', 'Please try again or contact customer support')
                setLoading(false)
            }
        )
    }

    const formattedDate = moment(donation.deliveryDetails.pickupTimeScheduled).format('ddd M/D/YY h:mm A').toLowerCase();

    return (
        <div className="history-card">
            <div className="history-card-header">
                <div className="history-card-date-container">
                <h1 className="history-card-text-date">{formattedDate}</h1>
                </div>
                <div className="history-card-total-container">
                <h2 className="history-card-text-total">{taxSavingTotalFormat}</h2>
                <h2 className="history-card-text-lbs">{lbsFormat} lbs</h2>
                </div>
                <div>
                {!selected ?
                    <img 
                        onClick={() => setSelected(!selected)}
                        src={PlusIcon} 
                        className="history-card-toggle-icon" 
                        alt="Expand" 
                    />
                    :
                    <img 
                        onClick={() => setSelected(!selected)}
                        src={MinusIcon} 
                        className="history-card-toggle-icon" 
                        alt="Expand" 
                    />
                }
                </div>
            </div>
    
          {selected && (
    <div className="history-card-expanded-container">
      {/* Replace loading with your loading state or logic */}
      {loading ? 
        <div className="upcoming-add-item-container">
          {/* Replace with your web loading indicator */}
          <div className="web-loading-indicator"></div>
        </div>
        :
        <div>
            <div className="upcoming-add-item-container">
                {/* Replace CustomButton with your web button component */}
                <CustomButton 
                    color='orange'
                    title='ADD ITEMS'
                    onClick={() => {
                        // Your existing logic
                        setAddItem(true);
                    }}
                    style={{marginRight: 5, maxWidth: 150}}
                />
                <CustomButton 
                    color="white"
                    title="CANCEL DONATION"
                    onClick={handleCancelDonation}
                    style={{marginLeft: 5, maxWidth: 150}}
                />
                {addItem && (
                    <Background>
                        <StyledModal
                            isOpen={true}
                            onRequestClose={() => setAddItem(false)}
                            ariaHideApp={false}  // Only for development, should be handled properly for accessibility
                            className="unique-modal-class" 
                            
                        >
                            <CloseIconContainer onClick={() => setAddItem(false)}>
                                <IoCloseSharp size={35} className='subscribe_modal_icon_close' />
                            </CloseIconContainer>
                            <Donate 
                                setAddScheduleItem={setAddItem} 
                                donationId={donation.id}
                                scheduledDonation={true}
                                modalRef={modalRef}
                                isInModal={true}
                            />
                        </StyledModal>
                    </Background>
                )}
            </div>
          {/* Replace the following section with your web list rendering logic */}
          {donation?.bag?.items?.length > 0 ? (
            <div className="checkout-items-container">
                {donation?.bag?.items?.map(item => (
                    <CheckoutItem
                        key={item.itemId}
                        itemId={item.itemId}
                        itemType={item.itemType}
                        category={item.category}
                        cost={item.cost}
                        fmv={item.fmv}
                        lbs={item.lbs}
                        taxSaving={item.taxSaving}
                        scheduledDonation={true}
                        donationId={donation.id}
                    />
                ))}
            </div>
          ) : (
            <div className="empty-schedule-container">
                <div className="empty-schedule-box">
                    <p className="empty-schedule-title">
                    YOUR DONATION IS EMPTY
                    </p>
                    <br />
                    <p className="empty-schedule-title">
                    ADD ITEMS BEFORE PICKUP TIME!
                    </p>
                </div>
            </div>
          )}
            <div className="history-card-expanded-donation-totals">
                <a 
                    href={donation.trackingLink} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="history-card-expanded-donation-link-text"
                >
                    <h2 className="history-card-expanded-donation-link-text">
                        <span style={{textDecoration: 'underline'}}>Tracking Link</span>
                        <span>{' '}</span><FaExternalLinkAlt size="0.7em" />
                    </h2>
                </a>
                <h2 className="history-card-expanded-donation-delivery-fee-text">
                Delivery Fee:{' '}
                <span className="currency-text">
                    {/* Replace with your currency formatting logic */}
                    {new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(donation.deliveryEstimate)}
                </span>
                </h2>
          </div>
        </div>
      }
    </div>
  )}
        </div>
      );
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchUpdateScheduleBag: (action, foodItem, currentBag, donationId, onSuccess, onError) =>
        dispatch(updateScheduleBag({ action, foodItem, currentBag, donationId }, onSuccess, onError)),
    dispatchCancelDonation: (donation, onSuccess, onError) =>
        dispatch(cancelDonation({ donation }, onSuccess, onError)),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingDonationCard)