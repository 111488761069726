import React, { useState } from 'react'
import { IoCloseSharp } from "react-icons/io5"
import { connect } from 'react-redux'
import { createFoodItem } from '../../redux/actions/foodItem'
import { getMe } from '../../redux/actions/user'
import { NumericFormat } from 'react-number-format'
import { Ellipsis } from 'react-css-spinners'

import './styles.css'

const AddItem = ({ setAddItem, dispatchCreateItemFood, disptachGetMe, getme }) => {
    const [type, setType] = useState('')
    const [cost, setCost] = useState('')
    const [fmv, setFmv] = useState('')
    const [alert, setAlert] = useState(false)
    const [loading, setLoading] = useState(false)

    const handleSubmmit = () => {
        setLoading(true)

        if (!type || !cost) {
            setAlert(true)
            setLoading(false)
        } else {
            dispatchCreateItemFood(
                getme.id,
                cost,
                fmv,
                type,
                () => { disptachGetMe(); setAddItem(false); setAlert(false); setLoading(false) },
                () => { setLoading(false); setAlert(false) }
            )
        }
    }

    return (
        <div className='add_item_modal'>
            <div className='add_item'>
                <IoCloseSharp size={35} className='add_item_close' onClick={() => setAddItem(false)} />
                <p className='add_item_title'>Add New Item</p>
                <input
                    className="add_item_input"
                    type="text"
                    placeholder="Food type"
                    value={type}
                    maxLength={18}
                    onChange={(e) => setType(e.target.value)}
                />
                <NumericFormat
                    className='add_item_input'
                    onValueChange={(e) => setCost(e.value)}
                    placeholder="Cost of goods"
                    prefix={'$'}
                    decimalScale={2}
                    decimalSeparator="."
                    valueIsNumericString={true}
                    type="text"
                    fixedDecimalScale={true}
                    thousandSeparator=","
                    maxLength={10}
                    allowNegative={false}
                />
                <NumericFormat
                    className='add_item_input'
                    onValueChange={(e) => setFmv(e.value)}
                    placeholder="Fair market value"
                    prefix={'$'}
                    decimalScale={2}
                    decimalSeparator="."
                    valueIsNumericString={true}
                    type="text"
                    fixedDecimalScale={true}
                    thousandSeparator=","
                    maxLength={10}
                    allowNegative={false}
                />
                <button className="add_item_button" disabled={loading ? true : false} onClick={handleSubmmit}>
                    {
                        !loading ? 'Add Item' : <span> <Ellipsis color="#FFF" size={38} style={{ marginTop: 3 }} /></span>
                    }
                </button>
                {alert && (<p className='add_item_alert'>Insert missing field, name or costs to create a new item.</p>)}
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchCreateItemFood: (ownerId, cost, fmv, itemType, onSuccess, onError) =>
        dispatch(createFoodItem({ ownerId, cost, fmv, itemType }, onSuccess, onError)),
    disptachGetMe: () => dispatch(getMe()),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(AddItem)
