import React, { useState } from 'react';
import Select from 'react-select';

const weekdays = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'white' : 'black',
    padding: 10,
  }),
  container: () => ({
    width: 200,
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: 'none',
    };
  },
  multiValueLabel: (styles, { data }) => {
    return {
      ...styles,
      color: data.color,
    };
  },
};

function ScheduleSelect() {
  const [selectedDays, setSelectedDays] = useState([]);

  const handleChange = (selectedOptions) => {
    setSelectedDays(selectedOptions);
  };

  const sortedSelectedDays = selectedDays.sort((a, b) =>
    weekdays.indexOf(a.label) - weekdays.indexOf(b.label)
  );

  const formatOptionLabel = ({ label }) => (
    <div>
      <input
        type="checkbox"
        checked={selectedDays.some(day => day.label === label)}
        onChange={() => {}}
      />
      {label}
    </div>
  );

  const options = weekdays.map(day => ({ label: day, value: day }));

  return (
    <Select
      isMulti
      options={options}
      onChange={handleChange}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      isClearable={false}
      components={{
        Option: props => (
          <div>
            <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => props.selectOption(props.option)}
            />
            {props.label}
          </div>
        ),
      }}
      value={sortedSelectedDays}
      formatOptionLabel={formatOptionLabel}
      styles={customStyles}
    />
  );
}

export default ScheduleSelect;
