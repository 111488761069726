import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from "react-router-dom"
import { Link } from "react-router-dom"
import { getMe } from '../../redux/actions/user'
import { connect } from "react-redux"
import LogoRight from "../../assets/images/logo_right.svg"
import { getRecipientsAvailable } from '../../redux/actions/delivery'
import HomeIcon from '../../assets/icons/home.svg'
import HomeIconOrange from '../../assets/icons/home_orange.svg'
import HistoryIcon from '../../assets/icons/history.svg'
import HistoryIconOrange from '../../assets/icons/history_orange.svg'
import BoxIconOrange from '../../assets/icons/box_orange.svg'
import BoxIcon from '../../assets/icons/box.svg'
import BoxCheckIcon from '../../assets/icons/box_check.svg'
import AccountIcon from '../../assets/icons/account.svg'
import AccountIconOrange from '../../assets/icons/account_orange.svg'
import CarFrontIcon from '../../assets/icons/car_front.svg'
import Schedule from '../../assets/icons/schedule.svg'
import ScheduleOrange from '../../assets/icons/schedule_orange.svg'
import BoxCheck from '../../assets/icons/box_check.svg'
import ScheduleIcon from '../../assets/icons/schedule.svg'
import CardIcon from '../../assets/icons/card.svg'
import CarPlusIcon from '../../assets/icons/car_plus.svg'
import CarRightIcon from '../../assets/icons/car_right.svg'

import './styles.css'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const Drawer = ({ disptachGetMe, getme }) => {
    const [open, setOpen] = useState(true)
    const [path, setPath] = useState("/")
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const { state } = useLocation()

    const location = useLocation()
    let menuRef = useRef()

    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef?.current?.contains(event.target)) {
                setOpen(true)
            }
        }
        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(() => {
        setPath(location.pathname)
    }, [location.pathname])

    const ongoingDelivery = getme?.donations?.filter(item => item.status === 'open')

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    return (
        <div className='drawer'>
            <div className='header'>
                <div className='header_container'>
                    <React.Fragment>
                        {windowDimensions.width > 850 ?
                            <>{
                                path === '/' && (
                                    <div className='header_page_title_container'>
                                        <img src={HomeIcon} className='header_page_title_icon_home' />
                                        <p className='header_page_title'>Hi, {getme.restaurant}!</p>
                                    </div>
                                )
                            }
                                {
                                    path === '/donate' && (
                                        <div className='header_page_title_container'>
                                            <img src={BoxCheck} className='header_page_title_icon_box' />
                                            <p className='header_page_title'>Donate</p>
                                        </div>
                                    )
                                }
                                {
                                    path === '/history' && (
                                        <div className='header_page_title_container'>
                                            <img src={HistoryIcon} className='header_page_title_icon_history' />
                                            <p className='header_page_title'>Donation history</p>
                                        </div>
                                    )
                                }
                                {
                                    path === '/account' && (
                                        <div className='header_page_title_container'>
                                            <img src={AccountIcon} className='header_page_title_icon_account' />
                                            <p className='header_page_title'>{getme.restaurant}</p>
                                        </div>
                                    )
                                }
                                {
                                    path === '/schedule' && (
                                        <div className='header_page_title_container'>
                                            <img src={ScheduleIcon} className='header_page_title_icon_history' />
                                            <p className='header_page_title'>Schedule</p>
                                        </div>
                                    )
                                }
                                {
                                    path === '/billing' && (
                                        <div className='header_page_title_container'>
                                            <img src={CardIcon} className='header_page_title_icon_card' />
                                            <p className='header_page_title'>Billing</p>
                                        </div>
                                    )
                                }
                                {
                                    path === '/delivery' && (
                                        <div className='header_page_title_container'>
                                            <img src={CarRightIcon} className='header_page_title_icon_car' />
                                            <p className='header_page_title'>Ongoing Delivery</p>
                                        </div>
                                    )
                                }
                                {
                                    path === '/checkout' && (
                                        <div className='header_page_title_container'>
                                            <img src={state?.estimate ? CarPlusIcon : BoxIcon} className='header_page_title_icon_box' />
                                            <p className='header_page_title'>{
                                                state?.express ? 'Express Donate' :
                                                    state?.estimate ? 'Schedule Pickup' :
                                                        'Donation Information'}</p>
                                        </div>
                                    )
                                }
                            </> :
                            <div>
                                <img src={LogoRight} className='header_logo_right' />
                            </div>}
                        <div className='header_nav_icons'>
                            {ongoingDelivery?.length && (
                                <Link to="/delivery">
                                    <div className='header_nav_icons_checkout'>
                                        <p className='header_nav_icons_checkout_count'>{ongoingDelivery?.length}</p>
                                        <img src={CarFrontIcon} className='icon_delivery' />
                                    </div>
                                </Link>
                            )}
                            {getme?.bag?.totals?.itemCount > 0 ?
                                <Link to="/checkout">
                                    <div className='header_nav_icons_checkout'>
                                        <p className='header_nav_icons_checkout_count_icon'>{getme.bag.totals.itemCount}</p>
                                        <img src={BoxCheckIcon} className='icon_shop' />
                                    </div>
                                </Link>
                                :
                                <Link to="/checkout">
                                    <img src={BoxCheckIcon} className='icon_shop' />
                                </Link>
                            }
                        </div>
                    </React.Fragment>
                </div>
                {open && windowDimensions.width > 850 && (
                    <div className='drawer_container'>
                        <div className='header_logo_icon_container'>
                            <img src={LogoRight} className='header_logo_right' />
                        </div>
                        <div className='drawer_user_name'>
                            <div className='drawer_user_name_circle'>
                                <p className='drawer_user_name_circle_text'>{getme?.restaurant?.split(" ").map((n) => n[0]).slice(0, 2)}</p>
                            </div>
                            <p className='drawer_user_name_title'>{getme?.restaurant}</p>
                        </div>
                        <div className='drawer_menu' ref={menuRef}>
                            <Link to="/" onClick={() => setOpen(true)} className='drawer_menu_item'>
                                <img src={path === '/' ? HomeIconOrange : HomeIcon} className='drawer_menu_item_icon' />
                                <p className={path === '/' ? 'drawer_menu_item_name_active' : 'drawer_menu_item_name'}>Home</p>
                            </Link>
                            <Link to="/donate" onClick={() => setOpen(true)} className='drawer_menu_item'>
                                <img
                                    src={path === '/donate' || path === '/checkout' ? BoxIconOrange : BoxIcon}
                                    className='drawer_menu_item_icon'
                                    style={{ height: 50, width: 36, marginRight: -5 }}
                                />
                                <p className={path === '/donate' || path === '/checkout' ? 'drawer_menu_item_name_active' : 'drawer_menu_item_name'}>Donate</p>
                            </Link>
                            <Link to="/history" onClick={() => setOpen(true)} className='drawer_menu_item'>
                                <img src={path === '/history' ? HistoryIconOrange : HistoryIcon} className='drawer_menu_item_icon' />
                                <p className={path === '/history' ? 'drawer_menu_item_name_active' : 'drawer_menu_item_name'}>History</p>
                            </Link>
                            <Link to="/account" onClick={() => setOpen(true)} className='drawer_menu_item'>
                                <img
                                    src={path === '/account' || path === '/billing' ? AccountIconOrange : AccountIcon}
                                    className='drawer_menu_item_icon'
                                    style={{ width: 27, height: 27, marginRight: 4 }}
                                />
                                <p className={path === '/account' || path === '/billing' ? 'drawer_menu_item_name_active' : 'drawer_menu_item_name'}>Account</p>
                            </Link>
                            <Link to="/schedule" onClick={() => setOpen(true)} className='drawer_menu_item'>
                                <img
                                    src={path === '/schedule' ? ScheduleOrange : Schedule}
                                    className='drawer_menu_item_icon'
                                    style={{ width: 27, height: 27, marginRight: 4 }}
                                />
                                <p className={path === '/schedule' ? 'drawer_menu_item_name_active' : 'drawer_menu_item_name'}>Schedule</p>
                            </Link>
                        </div>
                    </div>
                )}


                {windowDimensions.width < 850 && (
                    <div className='mobile_nav_bar'>
                        <Link className='mobile_nav_item' to="/">
                            <img src={path === '/' ? HomeIconOrange : HomeIcon} className='mobile_nav_icon' />
                            <p className={path === '/' ? 'mobile_nav_title_active' : 'mobile_nav_title'}>Home</p>
                        </Link>
                        <Link className='mobile_nav_item' to="/account">
                            <img src={path === '/account' ? AccountIconOrange : AccountIcon} className='mobile_nav_icon' style={{ width: 29, height: 29 }} />
                            <p className={path === '/account' ? 'mobile_nav_title_active' : 'mobile_nav_title'}>Account</p>
                        </Link>
                        <Link className='mobile_nav_item' to="/history">
                            <img src={path === '/history' ? HistoryIconOrange : HistoryIcon} className='mobile_nav_icon' />
                            <p className={path === '/history' ? 'mobile_nav_title_active' : 'mobile_nav_title'}>History</p>
                        </Link>
                        <Link className='mobile_nav_item' to="/donate">
                            <img src={path === '/donate' || path === '/checkout' ? BoxIconOrange : BoxIcon} style={{ height: 45, width: 45, marginBottom: -5 }} />
                            <p className={path === '/donate' || path === '/checkout' ? 'mobile_nav_title_active' : 'mobile_nav_title'} style={{ paddingBottom: 8 }}>Donate</p>
                        </Link>
                        <Link className='mobile_nav_item' to="/schedule">
                            <img src={path === '/schedule' ? ScheduleOrange : Schedule} className='mobile_nav_icon' />
                            <p className={path === '/schedule' ? 'mobile_nav_title_active' : 'mobile_nav_title'} >Schedule</p>
                        </Link>
                    </div>
                )}
            </div>

        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchGetRecipients: (recipients, onSuccess, onError) =>
        dispatch(getRecipientsAvailable({ recipients }, onSuccess, onError)),
})


const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);