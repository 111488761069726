import React, { useState } from 'react'
import Logo from '../../assets/images/logo.png'
import { Ellipsis } from 'react-css-spinners'
import * as IoIcons from "react-icons/io5"
import { connect } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { resetPassword } from '../../redux/actions/auth'

import './styles.css'

const ResetPassword = ({ dispatchResetPassword }) => {
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [serverError, setServerError] = useState('')
    const [loading, setLoading] = useState(false)
    const [isSecure, setIsSecure] = useState("password")
    const [isSecureConfirm, setIsSecureConfirm] = useState("password")
    const { token } = useParams()
    const navigate = useNavigate()

    const resetPassword = (event) => {
        event.preventDefault()
        setLoading(true)
        dispatchResetPassword(
            token,
            password,
            passwordConfirm,
            () => {setLoading(false); setServerError(''); navigate('/');},
            (error) => { setServerError(error.error); setLoading(false) }
        )
    }

    return (
        <div className='auth'>
            <div className='auth_container'>
                <img src={Logo} alt="logo" className='auth_logo' />
                <h2 className='auth_title'>Earn money. Save the world.</h2>
                <form>
                    <div style={{ width: "100%", position: 'relative' }}>
                        {isSecure === "password" ?
                            <IoIcons.IoEye size={20} className="login-icons-view" onClick={() => setIsSecure("text")} />
                            : <IoIcons.IoEyeOff size={20} className="login-icons-view" color="#dddfe2" onClick={() => setIsSecure("password")} />
                        }
                        <input
                            className="login-input"
                            type={isSecure}
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div style={{ width: "100%", position: 'relative' }}>
                        {isSecureConfirm === "password" ?
                            <IoIcons.IoEye size={20} className="login-icons-view" onClick={() => setIsSecureConfirm("text")} />
                            : <IoIcons.IoEyeOff size={20} className="login-icons-view" color="#dddfe2" onClick={() => setIsSecureConfirm("password")} />
                        }
                        <input
                            className="login-input"
                            type={isSecureConfirm}
                            placeholder="Confirm passsword"
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.target.value)}
                        />
                    </div>
                    {serverError ? <p className="login-error">{serverError}</p> : null}
                    <button className="login-button" disabled={loading ? true : false} onClick={resetPassword}>
                        {
                            !loading ? 'Reset Password' : <span> <Ellipsis color="#FFF" size={38} style={{ marginTop: 3 }} /></span>
                        }
                    </button>
                </form>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchResetPassword: (token, password, passwordConfirm, onSuccess, onError) =>
        dispatch(resetPassword({ token, password, passwordConfirm }, onSuccess, onError)),
})

export default connect(null, mapDispatchToProps)(ResetPassword)
