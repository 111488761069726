export const API = "API"
export const RESET_USER_INFO = "RESET_USER_INFO"
export const SET_USER_INFO = "SET_USER_INFO"
export const GET_ME = "GET_ME"
export const UPDATE_ME = "UPDATE_ME"
export const CREATE_PAYMENT_METHOD = "CREATE_PAYMENT_METHOD"
export const CREATE_DONATION = "CREATE_DONATION"
export const UPDATE_DONATION = "UPDATE_DONATION"
export const DELETE_DONATION = "DELETE_DONATION"
export const CREATE_FOOD_ITEM = "CREATE_FOOD_ITEM"
export const UPDATE_FOOD_ITEM = "UPDATE_FOOD_ITEM"
export const DELETE_FOOD_ITEM = "DELETE_FOOD_ITEM"
export const CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION"
export const CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION"
export const RECIPIENTS_AVAILABLE = "RECIPIENTS_AVAILABLE"
export const CREATE_REPORT_ONE = "CREATE_REPORT_ONE"
export const CREATE_REPORT_TWO = "CREATE_REPORT_TWO"
export const CANCELED_DONATION = "CANCELED_DONATION"
export const EDITED_DONATION = "EDITED_DONATION"
export const CREATE_FOOD_GROUP = "CREATE_FOOD_GROUP"
export const UPDATE_FOOD_GROUP = "UPDATE_FOOD_GROUP"
export const DELETE_FOOD_GROUP = "DELETE_FOOD_GROUP"
export const CREATE_SCHEDULE = "CREATE_SCHEDULE"
export const UPDATE_SCHEDULE = "CREATE_SCHEDULE"
export const DELETE_SCHEDULE = "DELETE_SCHEDULE"
export const UPDATE_SCHEDULE_BAG = "UPDATE_SCHEDULE_BAG"
export const RUN_UPCOMING_JOB = "RUN_UPCOMING_JOB";