import React from "react"
import { Ellipsis } from 'react-css-spinners'

import './styles.css'

const LoadingButton = ({ onClick, title, color, style, isLoading }) => {

    return (

        <button
            onClick={onClick}
            className={`custom_button_${color}`}
            style={style}
            disabled={isLoading ? true : false}>
            {
                !isLoading ? title : <span> <Ellipsis color="#FFF" size={45 } style={{ marginTop: 5  }} /></span>
            }
        </button>
    )
}

export default LoadingButton