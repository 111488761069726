import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getMe } from '../../redux/actions/user'
import { updateDonation, cancelDonation } from '../../redux/actions/donation'
import { useNavigate } from "react-router-dom"
import moment from 'moment'
import { getDeliveryStatus } from '../../redux/actions/delivery'

import './styles.css'

const FoodItem = ({ disptachGetMe, getme, dispatchUpdateDonation, dispatchGetShipdayStatus, dispatchCancelDonation, donation, donationId, deliveryStatus, trackingLink, dropoffTimeEstimated, pickupTimeEstimated, orderId }) => {
    const [status, setStatus] = useState('')
    const [subStatus, setSubstatus] = useState('')
    const [flow, setFlow] = useState(1)
    const navigate = useNavigate()

    useEffect(() => {
        const interval = setInterval(() => {
            disptachGetMe()
            dispatchGetShipdayStatus(donationId)
        }, 30000)
        return () => clearInterval(interval);
    }, [])

    useEffect(() => {
        mapToStatus(deliveryStatus)
        closeDonation()
        closeDeliveryPage()
    }, [deliveryStatus])

    const mapToStatus = (deliveryStatus) => {
        switch (deliveryStatus) {
            case 'NOT_ACCEPTED':
                setStatus('Delivery Created')
                setSubstatus('Working on it now')
                setFlow(1)
                break;
            case 'STARTED':
                setStatus('Delivery Confirmed')
                setSubstatus('Heading to your store.')
                setFlow(2)
                break;
            case 'PICKED_UP':
                setStatus('Delivery Picked Up')
                setSubstatus('The delivery is on the way.')
                setFlow(3)
                break;
            case 'ALREADY_DELIVERED':
                setStatus('Delivered')
                setSubstatus('Donation was successfully delivered.')
                setFlow(4)
                break;
            case 'FAILED_DELIVERY':
                setStatus('Canceled')
                setSubstatus('The delivery was canceled.')
                break;
            default:
                setStatus('Delivery Created')
                setSubstatus('Working on it now')
                setFlow(1)
        }
    }

    const timeNow = new Date().toISOString({ timeZone: 'America/Los_Angeles' })
    const date1 = moment(timeNow)
    const date2 = moment(dropoffTimeEstimated)
    const differenceInMs = date2.diff(date1)
    const duration = moment.duration(differenceInMs)
    const differenceInMinutes = duration.asMinutes()

    const closeDonation = () => {

        if (deliveryStatus === 'ALREADY_DELIVERED' || deliveryStatus === 'DASHER_DROPPED_OFF') {
            dispatchUpdateDonation(
                'closed',
                donationId,
                () => { disptachGetMe(); navigate('/') },
                (error) => console.log(error)
            )
        }
    }

    const closeDeliveryPage = () => {
        const openDonations = getme.donations.filter(item => item.status === 'open')

        if (openDonations.length === 0) {
            navigate('/')
        }
    }

    const handleCancelDonation = () => {
        dispatchCancelDonation(
            donation,
            () => {
                navigate('/')
            },
            (error) => console.log(error)
        )
    }

    return (
        <div className='delevery_tracking'>\
            <div className='delevery_tracking_time_container'>
                {flow === 1 && (
                    <div className='delivery_tracking_cancel'>
                        <p className='delevery_tracking_title'>{status}</p>
                        <button className='delivery_cancel_donation_button' onClick={handleCancelDonation}>Cancel Donation</button>
                    </div>
                )}
                {(flow === 2) && (
                    <div className='delivery_tracking_flow_container'>
                        <p className='delevery_tracking_title'>{status}</p>
                        <p className='delevery_tracking_time_description'>Estimate time to pick up: {moment(pickupTimeEstimated).format('hh:mm a')}</p>
                    </div>
                )}
                {(flow === 3) && (
                    <div className='delivery_tracking_flow_container'>
                        <p className='delevery_tracking_title'>Arriving in {differenceInMinutes < 1 ? 1 : Math.round(differenceInMinutes)} min</p>
                    </div>
                )}
                {(flow === 4) && (
                    <p className='delevery_tracking_title'>{status}</p>
                )}
            </div>
            {status !== 'Canceled' ?
                <div className='delivery_tracking_step_container'>
                    <div className={flow > 1 ? 'delivery_tracking_step' : flow === 1 ? 'delivery_tracking_step_active' : 'delivery_tracking_step_inactive'} />
                    <div className={flow > 2 ? 'delivery_tracking_step' : flow === 2 ? 'delivery_tracking_step_active' : 'delivery_tracking_step_inactive'} />
                    <div className={flow > 3 ? 'delivery_tracking_step' : flow === 3 ? 'delivery_tracking_step_active' : 'delivery_tracking_step_inactive'} />
                    <div className={flow > 4 ? 'delivery_tracking_step' : flow === 4 ? 'delivery_tracking_step_active' : 'delivery_tracking_step_inactive'} />
                </div>
                :
                <div className='delivery_tracking_step_container'>
                    <div className='delivery_tracking_step_canceled' />
                    <div className='delivery_tracking_step_canceled' />
                    <div className='delivery_tracking_step_canceled' />
                    <div className='delivery_tracking_step_canceled' />
                </div>
            }
            <div className='delevery_tracking_status_container'>
                <div style={{ flex: 2 }}>
                    <p className='delevery_tracking_status_title'>{subStatus}</p>
                    {flow <= 3 && status !== 'Canceled' && (
                        <p className='delevery_tracking_status_time'>Estimate time to delivery: {differenceInMinutes < 1 && flow === 3 ? moment(date1).format('hh:mm a') : moment(dropoffTimeEstimated).format('hh:mm a')}</p>
                    )}
                    {flow > 3 && status !== 'Canceled' && (
                        <p className='delevery_tracking_status_time'>Delivered at: {moment(timeNow).format('hh:mm a')}</p>
                    )}
                    {status === 'Canceled' && (
                        <p className='delevery_tracking_status_time'>Please retry your donation, the delivery fee will be refunded soon.</p>
                    )}
                </div>
                <div style={{ flex: 1 }}>
                    <p className='delevery_tracking_status_id'>Order ID: #{orderId}</p>
                    <p className='delevery_tracking_status_link'>
                        <a href={trackingLink} target="_blank" rel="noreferrer" className={status !== 'Canceled' ? 'delevery_tracking_status_link' : 'delevery_tracking_status_link_canceled'}>Tracking URL</a>
                    </p>
                </div>
            </div>
            {status === 'Canceled' ?
                <button className='delevery_tracking_close_button_canceled' onClick={closeDonation}>Done</button>
                : null
            }
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchUpdateDonation: (status, donationId, onSuccess, onError) =>
        dispatch(updateDonation({ status }, donationId, onSuccess, onError)),
    dispatchCancelDonation: (donation, onSuccess, onError) =>
        dispatch(cancelDonation({ donation }, onSuccess, onError)),
    dispatchGetShipdayStatus: (donationId) =>
        dispatch(getDeliveryStatus({ donationId })),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(FoodItem)