import * as constants from '../constants'

export const createDonation = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/donation',
        data,
        success: (response) => createdDonation(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const createEstimation = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/donation/estimate',
        data,
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const updateDonation = (data, donationId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'PATCH',
        url: `/donation/${donationId}`,
        data,
        success: (response) => updatedDonation(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const deleteDonation = (data, userId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: `/donation/${userId}`,
        data,
        success: (response) => deletedDonation(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const cancelDonation = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/donation/cancel',
        data,
        success: (response) => canceledDonation(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const editDonation = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/donation/edit',
        data,
        success: (response) => editedDopnation(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

const createdDonation = (data) => ({
    type: constants.CREATE_DONATION,
    payload: data,
})

const updatedDonation = (data) => ({
    type: constants.UPDATE_DONATION,
    payload: data,
})

const deletedDonation = (data) => ({
    type: constants.DELETE_DONATION,
    payload: data,
})

const canceledDonation = (data) => ({
    type: constants.CANCELED_DONATION,
    payload: data,
})

const editedDopnation = (data) => ({
    type: constants.EDITED_DONATION,
    payload: data,
})