import React, { useState, useEffect, useRef } from 'react';
import { IoClose, IoChevronDownOutline } from 'react-icons/io5'

import './styles.css'

const RecipientDropdown = ({ recipient, setRecipient, recipientsList }) => {
    const [open, setOpen] = useState(false)
    const [width, setWidth] = useState(0)
    const ref = useRef(null)

    useEffect(() => {
        setWidth(ref.current.clientWidth)
    })

    let menuRef = useRef()

    useEffect(() => {
        let handler = (event) => {
            if (!menuRef.current.contains(event.target)) {
                setOpen(false)
            }
        };

        document.addEventListener("mousedown", handler)

        return () => {
            document.removeEventListener("mousedown", handler)
        }
    })

    useEffect(() => {
        setRecipient(recipientsList[0])
    }, [])

    return (
        <div ref={menuRef} className='recipient_select'>
            <div className="recipient_select_container" onClick={() => setOpen(!open)} ref={ref}>
                <p className={recipient?.customerName?.length > 17 ? 'recipient_option_title_lower' : 'recipient_option_title'}>{recipient?.customerName?.slice(0, 32) || 'CHOOSE RECIPIENT'}</p>
                <div className='recipient_icon_containers'>
                    {open ?
                        <IoClose className='recipient_select_close_icon' /> :
                        <IoChevronDownOutline className='recipient_select_chevron_icon' />}
                </div>
            </div>
            {open && (
                <div className="recipient_select_container_option">
                    {recipientsList.map((item) => (
                        <button key={item.customerName} className="recipiton_option_button" onClick={() => { setRecipient(item); setOpen(!open) }}>
                            {item.customerName?.slice(0, 32)}
                        </button>
                    ))}
                </div>
            )}
        </div>

    )
}

export default RecipientDropdown