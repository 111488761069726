import * as constants from '../constants'

export const createSchedule = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/schedule',
        data,
        success: (response) => createdSchedule(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const updateSchedule = (scheduleId, data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'PATCH',
        url: `/schedule/${scheduleId}`,
        data,
        success: (response) => updatedSchedule(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const deleteSchedule = (scheduleId, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'DELETE',
        url: `/schedule/${scheduleId}`,
        success: (response) => deletedSchedule(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const updateScheduleBag = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/schedule/bag',
        data,
        success: (response) => updatedScheduleBag(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const runUpcomingJob = (onSuccess, onError) => {
    return {
        type: constants.API,
        payload: {
            method: 'POST',
            url: '/schedule/upcoming',
            success: (response) => ranUpcomingJob(response),
            postProccessSuccess: onSuccess,
            postProccessError: onError,
        },
    };
};

const createdSchedule = (data) => ({
    type: constants.CREATE_SCHEDULE,
    payload: data,
})

const updatedSchedule = (data) => ({
    type: constants.UPDATE_SCHEDULE,
    payload: data,
})

const deletedSchedule = (data) => ({
    type: constants.DELETE_SCHEDULE,
})

const updatedScheduleBag = (data) => ({
    type: constants.UPDATE_SCHEDULE_BAG,
})

const ranUpcomingJob = (data) => ({
    type: constants.RUN_UPCOMING_JOB,
    payload: data,
});