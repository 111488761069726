import React from 'react'
import { useState } from 'react'
import { IoCloseSharp } from "react-icons/io5"
import { editDonation } from '../../redux/actions/donation'
import { getMe } from '../../redux/actions/user'
import { connect } from 'react-redux'
import { NumericFormat } from 'react-number-format'

import './styles.css'

const ModalEditDonation = ({ setEditModal, item, items, donationId, dispatchEditDonation, disptachGetMe }) => {
    const [taxSaving, setTaxSaving] = useState('')
    const [lbs, setLbs] = useState('')
    const [editor, setEditor] = useState('')
    const [reason, setReason] = useState('')
    const [alert, setAlert] = useState(false)
    const [message, setMessage] = useState('')

    const index = items.findIndex((x) => x.itemId === item.itemId)
    const previous = { taxSaving: item.taxSaving, lbs: item.lbs }

    const updateDonation = () => {
        items[index].taxSaving = Number(taxSaving) || items[index].taxSaving
        items[index].lbs = Number(lbs) || items[index].lbs

        const date = new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })

        if (!editor && !reason) {
            setAlert(true)
            setMessage('Please enter the editor name and reason.')
            return
        }

        if (!lbs && !taxSaving) {
            setAlert(true)
            setMessage('Please enter the tax value or weight.')
            return
        }

        const auditLog = {
            name: item.itemType || item.category,
            date: date,
            editor: editor,
            previous: { taxSaving: previous.taxSaving, lbs: previous.lbs },
            current: { taxSaving: items[index].taxSaving = Number(taxSaving) || previous.taxSaving, lbs: items[index].lbs = Number(lbs) || previous.lbs, },
            reason: reason
        }

        dispatchEditDonation(
            items,
            auditLog,
            donationId,
            () => {
                disptachGetMe();
                setEditModal(false);
                setAlert(false);
                setMessage('');
            },
            () => {
                setAlert(true);
                setMessage('Something went wrong please try again.');
            }
        )
    }

    return (
        <div className='modal_edit_donation'>
            <div className='modal_edit_donation_container'>
                <IoCloseSharp size={35} className='modal_edit_donation_icon_close' onClick={() => { setEditModal(false); setAlert(false) }} />
                <p className='modal_edit_donation_title'>{item.itemType || item.category}</p>
                <div className='modal_edit_donation_input_container'>
                    <p className='modal_edit_donation_label'>Tax Saving</p>
                    <NumericFormat
                        className='modal_edit_donation_input'
                        onValueChange={(e) => setTaxSaving(Number(e.value))}
                        placeholder={`$${item.taxSaving}/lbs`}
                        prefix={'$'}
                        suffix={'/lbs'}
                        decimalScale={2}
                        decimalSeparator="."
                        valueIsNumericString={true}
                        type="text"
                        fixedDecimalScale={true}
                        thousandSeparator=","
                        maxLength={11}
                        allowNegative={false}
                    />
                </div>
                <div className='modal_edit_donation_input_container'>
                    <p className='modal_edit_donation_label'>Weight</p>
                    <NumericFormat
                        className='modal_edit_donation_input'
                        onValueChange={(e) => setLbs(Number(e.value))}
                        placeholder={`${item.lbs}/lbs`}
                        suffix={'/lbs'}
                        decimalSeparator="."
                        valueIsNumericString={true}
                        type="text"
                        fixedDecimalScale={true}
                        thousandSeparator=","
                        maxLength={10}
                        allowNegative={false}
                    />
                </div>
                <div className='modal_edit_donation_input_container'>
                    <p className='modal_edit_donation_label'>Your Name</p>
                    <input
                        className='modal_edit_donation_input'
                        placeholder='Enter Your Name (required)'
                        onChange={(e) => setEditor(e.target.value)}
                    />
                </div>
                <div className='modal_edit_donation_input_container'>
                    <p className='modal_edit_donation_label'>Reason</p>
                    <input
                        className='modal_edit_donation_input'
                        placeholder='Reason for Edit (required)'
                        onChange={(e) => setReason(e.target.value)}
                    />
                </div>
                <p className='add_item_alert'>{message}</p>
                <button className='modal_edit_button_update' onClick={updateDonation}>Update</button>
                <button className='modal_edit_button_cancel' onClick={() => { setEditModal(false); setAlert(false); }}>Cancel</button>

            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchEditDonation: (items, auditLog, donationId, onSuccess, onError) =>
        dispatch(editDonation({ items, auditLog, donationId }, onSuccess, onError)),
})

export default connect(null, mapDispatchToProps)(ModalEditDonation)
