import React from "react";
import { Routes, Route, Navigate } from "react-router-dom"
import { connect } from "react-redux";

import Drawer from '../components/Drawer'
import Auth from '../pages/Auth'
import Home from '../pages/Home'
import Donate from '../pages/Donate'
import History from '../pages/History'
import Billing from '../pages/Billing'
import Checkout from '../pages/Checkout'
import ResetPassword from '../pages/ResetPassword'
import Delivery from "../pages/Delivery"
import Account from "../pages/Account"
import Schedule from "../pages/Schedule"

const App = ({ user }) => {

    return (
        <React.Fragment>
            {user.isLoggedIn && <Drawer />}
            <div className="App">
                {
                    user.isLoggedIn ? (
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/donate" element={<Donate />} />
                            <Route path="/history" element={<History />} />
                            <Route path="/billing" element={<Billing />} />
                            <Route path="/checkout" element={<Checkout />} />
                            <Route path="/delivery" element={<Delivery />} />
                            <Route path="/account" element={<Account />} />
                            <Route path="/schedule" element={<Schedule />} />
                            <Route path="*" element={<Navigate replace to="/" />} />
                        </Routes>
                    ) : (
                        <Routes>
                            <Route path="/" element={<Auth />} />
                            <Route path="/signup" element={<Auth />} />
                            <Route path="/reset-password/:token" element={<ResetPassword />} />
                            <Route path="*" element={<Navigate replace to="/" />} />
                        </Routes>
                    )
                }
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(App)