import React, { useEffect, useState, forwardRef } from 'react'
import { connect } from 'react-redux'
import { getMe } from '../../redux/actions/user'
import { createSchedule, runUpcomingJob } from '../../redux/actions/schedule'
import { IoChevronDownOutline } from "react-icons/io5"
import DatePicker from "react-datepicker"
import { IoCloseSharp } from "react-icons/io5"
import moment from 'moment'

import LoadingButton from '../../components/LoadingButton'
import CustomButton from '../../components/CustomButton'
import ScheduleCard from '../../components/ScheduleCard'
import AddToSchedule from '../../components/AddToSchedule'
import UpcomingDonationCard from '../../components/UpcomingDonationCard';

import "react-datepicker/dist/react-datepicker.css"
import ScheduleIcon from '../../assets/icons/schedule.svg'
import PlusIcon from '../../assets/icons/plus.svg'
import './styles.css'

const CustomInput = forwardRef(({ value, onClick, text }, ref) => (
    <button className="schedule_selector" onClick={onClick} ref={ref}>
        {value || text}
    </button>
))


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    }
}

const Schedule = ({ getme, disptachGetMe, dispatchRunUpcomingJob, dispatchCreateSchedule }) => {
    const [cadence, setCadence] = useState('')
    const [weekday, setWeekday] = useState([])
    const [monthlyday, setMonthlyday] = useState('')
    const [singleday, setSingleday] = useState('')
    const [time, setTime] = useState('')
    const [loading, setLoading] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const [display, setDisplay] = useState('upcoming')
    const [addToSchedule, setAddToSchedule] = useState(false)
    const [upcomingDonations, setUpcomingDonations] = useState(true)
    const [recurringSchedule, setRecurringSchedule] = useState(false) 

    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const scheduledDonation = 
        getme.donations
            .filter(item => item.status === ('scheduled' || 'open'))
            .sort((a, b) => 
                new Date(a.deliveryDetails.pickupTimeScheduled) - new Date(b.deliveryDetails.pickupTimeScheduled));
    
    const weekdayToNumber = (weekday) => {
        const weekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
        return weekdays.indexOf(weekday.toLowerCase());
    };
        
    const sortCadence = (cadence) => {
        switch (cadence) {
            case 'single':
            return 0;
            case 'weekly':
            return 1;
            case 'bi-weekly':
            return 2;
            case 'monthly':
            return 3;
            default:
            return 4;
        }
    };
        
    const recurringDonations = getme?.schedule?.filter(item => item.cadence !== 'single') 
        .sort((a, b) => {
        // const recurringDonations = getme?.schedule?.sort((a, b) => {
        // Sort by cadence
            const cadenceA = sortCadence(a.cadence);
            const cadenceB = sortCadence(b.cadence);
            
            if (cadenceA < cadenceB) return -1;
            if (cadenceA > cadenceB) return 1;
            
            // Sort by date
            if (a.cadence === 'weekly' && b.cadence === 'weekly') {
                const weekdayA = weekdayToNumber(a.date);
                const weekdayB = weekdayToNumber(b.date);
                
                if (weekdayA < weekdayB) return -1;
                if (weekdayA > weekdayB) return 1;
            } else if (a.cadence === 'single' && b.cadence === 'single') {
                const dateA = new Date(a.date).getTime();
                const dateB = new Date(b.date).getTime();
            
                if (dateA < dateB) return -1;
                if (dateA > dateB) return 1;
            }
            
            // Sort by time
            const timeA = new Date(`1970-01-01T${a.time}Z`).getTime();
            const timeB = new Date(`1970-01-01T${b.time}Z`).getTime();
            
            if (timeA < timeB) return -1;
            if (timeA > timeB) return 1;
            
            return 0;
    });
                  
    const handleRunUpcoming = () => {
        setLoading(true);
        console.log('hit run upcoming')
        dispatchRunUpcomingJob(
            () => { 
                disptachGetMe(); 
                setLoading(false); 
                // setAlert(false); 
                console.log('Successfully added to schedule.');
            },
            (error) => {  
                alert('Error adding to schedule', 'Please try again'); 
                console.log(error); 
                setLoading(false) 
            }
        );
    };

    const emptyScheduleView = () => {
        return (
            <div className="empty-schedule-container">
                <div className="empty-schedule-box">
                    <p className="empty-schedule-title">
                        YOUR SCHEDULE IS EMPTY
                    
                    <br />
                    <br />
                        ADD A DONATION BELOW!
                    </p>
                </div>
            </div>
        );
    }
    


    // const cadences = ['Single', 'Weekly', 'Bi-Weekly', 'Monthly']
    // const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
    // const monthlydays = ['1st', '2nd', '3rd', '4th']
    // const biweeklyOptions = ['1st and 2nd', '1st and 3rd', '1st and 4th', '2nd and 3rd', '2nd and 4th', '3rd and 4th']

    // function addDays(days) {
    //     var result = new Date();
    //     result.setDate(result.getDate() + days)
    //     return result
    // }

    // const clickHandler = (array, value, setter) => {
    //     if (array.includes(value)) {
    //         return
    //     } else {
    //         const newArray = [...array, value]
    //         setter(newArray)
    //     }
    // }

    // const handleRemoveItem = (array, value, setter) => {
    //     setter(array.filter(item => item !== value))
    // }

    // const handleCreateSchedule = () => {
    //     setLoading(true)

    //     let date

    //     switch (cadence) {
    //         case 'single':
    //             const rowDate = moment(singleday)
    //             date = rowDate.format("YYYY-MM-DD")
    //             break;
    //         case 'weekly':
    //             date = weekday
    //             break
    //         case 'bi-weekly':
    //             date = { weekOfTheMonth: monthlyday, dayOfTheWeek: weekday }
    //             break
    //         case 'monthly':
    //             date = { weekOfTheMonth: Number(monthlyday), dayOfTheWeek: weekday[0] }
    //             break
    //     }

    //     if (!cadence || !date || !time) {
    //         console.log('Please select cadence, date and time')
    //         setLoading(false)
    //         return
    //     }

    //     dispatchCreateSchedule(
    //         cadence,
    //         date,
    //         moment(time).format().slice(11, 19),
    //         getme.id,
    //         getme.recipients,
    //         () => { disptachGetMe(); setLoading(false) },
    //         (error) => { console.log(error); setLoading(false) }
    //     )
    // }

    return (
        <div className='schedule'>
            {windowDimensions.width < 800 && (
                <div className='header_page_title_container'>
                    <img src={ScheduleIcon} className='header_page_title_icon_history' />
                    <p className='header_page_title'>Schedule</p>
                </div>
            )}
            <div className="schedule-header">
                {addToSchedule && <AddToSchedule setAddToSchedule={setAddToSchedule} />}
            </div>
            <div className="switch-container">
                <button 
                    className={`switch-option-left ${display === 'upcoming' ? 'selected-option-left' : ''}`}
                    onClick={() => {
                    setUpcomingDonations(true);
                    setRecurringSchedule(false);
                    setDisplay('upcoming');
                    }}
                >
                    <h2 className={`switch-text ${display === 'upcoming' ? 'switch-text-selected' : ''}`}>Upcoming<br />Donations</h2>
                </button>
                <button 
                    className={`switch-option-right ${display === 'recurring' ? 'selected-option-right' : ''}`}
                    onClick={() => {
                    setRecurringSchedule(true);
                    setUpcomingDonations(false);
                    setDisplay('recurring');
                    }}
                >
                    <h2 className={`switch-text ${display === 'recurring' ? 'switch-text-selected' : ''}`}>Recurring Schedule</h2>
                </button>
            </div>
            <div>
                {upcomingDonations && (
                    <>
                    {scheduledDonation.length === 0 && emptyScheduleView()}
                    <div className="schedule-cards-container">
                        {scheduledDonation.map(item => (
                            <UpcomingDonationCard key={item.id} donation={item} />
                        ))}
                    </div>
                    </>
                )}
                {recurringSchedule && (
                    <>
                        {recurringDonations.length === 0 && emptyScheduleView()}
                            <div className="schedule-cards-container">
                                {recurringDonations.map(item => (
                                    <ScheduleCard key={item.id} schedule={item} />
                                ))}                    
                            </div>
                    </>
                )}
            </div>
            {/* <div className="run-upcoming-container">
                <CustomButton 
                    // className="schedule-header-buttons"
                    color='blue'
                    title='Run Upcoming'
                    onClick={handleRunUpcoming}
                />
            </div> */}
                <button 
                    className="floating-button" 
                    onClick={() => setAddToSchedule(true)}
                >
                    <img 
                        src={PlusIcon}
                        className="plus-icon" 
                        alt="plus icon" 
                    />
            </button>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchRunUpcomingJob: (onSuccess, onError) => 
        dispatch(runUpcomingJob(onSuccess, onError)),
    dispatchCreateSchedule: (cadence, date, time, ownerId, recipients, onSuccess, onError) =>
        dispatch(createSchedule({ cadence, date, time, ownerId, recipients }, onSuccess, onError)),
})
const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)