import * as constants from '../constants'

export const createPaymentMethod = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/payment/create-method',
        data,        
        success: (response) => createdPaymentMethod(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

const createdPaymentMethod = (data) => ({
    type: constants.CREATE_PAYMENT_METHOD,
    payload: data,
})

export const createSubscription = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/payment/create-subscription',
        data,        
        success: (response) => createdSubscription(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

export const createAnnualDiscountSubscription = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/payment/create-annual-subscription',
        data,        
        success: (response) => createdSubscription(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

const createdSubscription = (data) => ({
    type: constants.CREATE_SUBSCRIPTION,
    payload: data,
})

export const cancelSubscription = (data, onSuccess, onError) => ({
    type: constants.API,
    payload: {
        method: 'POST',
        url: '/payment/cancel-subscription',
        data,        
        success: (response) => canceledSubscription(response),
        postProccessSuccess: onSuccess,
        postProccessError: onError,
    },
})

const canceledSubscription = (data) => ({
    type: constants.CANCEL_SUBSCRIPTION,
    payload: data,
})