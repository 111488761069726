import React, { useEffect, useState } from 'react'
import { getMe, updateUserData } from '../../redux/actions/user'
import { connect } from "react-redux"
import { getRecipientsAvailable } from '../../redux/actions/delivery'
import { useNavigate } from "react-router-dom"
import CustomButton from '../../components/CustomButton'
import LoadingButton from '../../components/LoadingButton'
import EarnIcon from '../../assets/icons/earn.svg'
import MealsIcon from '../../assets/icons/meals.svg'
import HomeIcon from '../../assets/icons/home.svg'
import { IoCloseSharp } from "react-icons/io5"

import './styles.css'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    }
}

const Home = ({ getme, disptachGetMe, dispatchGetRecipients, dispatchUpdateUserData }) => {
    const [serverError, setServerError] = useState('')
    const [loading, setLoading] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())
    const navigate = useNavigate()

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    let tax = []
    let weight = []
    let totalTax
    let totalWeight

    if (getme?.id) {
        for (const item of getme?.donations) {
            if (item.status !== "scheduled") {
                tax.push(item?.bag?.totals?.totalTaxSavings)
                weight.push(item?.bag?.totals?.totalWeight)
            }
        }

        totalTax = tax.reduce((accumulator, value) => {
            return accumulator + value
        }, 0)

        totalWeight = weight.reduce((accumulator, value) => {
            return accumulator + value
        }, 0)
    }

    const updateNotification = () => {
        dispatchUpdateUserData(
            { 'montlhyUpdateFmvAndCost': false },
            getme.id,
            () => disptachGetMe(),
            (error) => console.log(error)
        )
    }


    const getRecipients = () => {
        setLoading(true)
        dispatchGetRecipients(
            getme.recipients,
            () => {
                setLoading(false)
                navigate('/checkout', { state: { express: true } })
            },
            (error) => {
                setServerError(error.error)
                setLoading(false)
            }
        )
    }

    const totalTaxFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(totalTax)
    const totalMealsFormat = new Intl.NumberFormat('us').format(Math.round(totalWeight / 1.2))

    return (
        <div className='home'>
            {
                getme.notifications?.montlhyUpdateFmvAndCost && (
                    <div className='subscribe_modal'>
                        <div className='subscribe_modal_container'>
                            <IoCloseSharp size={35} className='subscribe_modal_icon_close' onClick={updateNotification} />
                            <p className='subscribe_modal_title'>Reminder</p>
                            <p className='subscribe_modal_subtitle'>Please review your costs and fair market values. Updating regularly ensures you optimize your deductions!
                            </p>
                            <button className='subscribe_modal_button' onClick={updateNotification}>Got it!</button>
                        </div>
                    </div>
                )
            }
            {windowDimensions.width < 800 && (
                <div className='header_page_title_container'>
                    <img src={HomeIcon} className='header_page_title_icon_home' />
                    <p className='header_page_title'>Hi, {getme.restaurant}!</p>
                </div>
            )}
            <div className='page_fix_container'>
                <div className='home_info_container'>
                    <div className='home_info_box'>
                        <p className='home_number_title'>{totalMealsFormat}</p>
                        <p className='home_text_subtitle'>meals served</p>
                        <img src={MealsIcon} className="home_info_box_icon" />
                    </div>
                    <div className='home_info_box'>
                        <p className='home_number_title'>{totalTaxFormat}</p>
                        <p className='home_text_subtitle'>tax deductions</p>
                        <img src={EarnIcon} className="home_info_box_icon" />
                    </div>
                </div>
                <div className='home_menu_buttons_container'>
                    <CustomButton
                        isLink={true}
                        to='/donate'
                        title='DONATE'
                        color='blue'
                        style={{ marginTop: 15 }}

                    />
                    <LoadingButton
                        isLoading={loading}
                        title='EXPRESS DONATE'
                        color='green'
                        state={{ express: true }}
                        style={{ marginTop: 15 }}
                        onClick={getRecipients}
                    />
                    {serverError ? <p className="login-error">{serverError}</p> : null}
                </div>
            </div>

        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchGetRecipients: (recipients, onSuccess, onError) =>
        dispatch(getRecipientsAvailable({ recipients }, onSuccess, onError)),
    dispatchUpdateUserData: (notifications, userId, onSuccess, onError) =>
        dispatch(updateUserData({ notifications }, userId, onSuccess, onError)),
})


const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(Home)