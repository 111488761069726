import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getMe, updateUserProfile } from '../../redux/actions/user'
import { updatePassword, logoutUser } from '../../redux/actions/auth'
import { Link } from "react-router-dom"
import * as IoIcons from "react-icons/io5"
import LoadingButton from '../../components/LoadingButton'
import CustomButton from '../../components/CustomButton'
import AccountIcon from '../../assets/icons/account.svg'

import './styles.css'

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    }
}

const History = ({ getme, disptachGetMe, dispatchUpdatePassword, dispatchLogout, dispatchUpdateInstruction }) => {
    const [password, setPassword] = useState('')
    const [passwordCurrent, setPasswordCurrent] = useState('')
    const [isSecure, setIsSecure] = useState("password")
    const [isSecureTwo, setIsSecureTwo] = useState("password")
    const [loadingPassword, setIsLoadingPassword] = useState(false)
    const [loadingInstruction, setIsLoadingInstruction] = useState(false)
    const [passwordError, setPasswordError] = useState('')
    const [passwordSuccess, setPasswordSuccess] = useState(false)
    const [instruction, setInstruction] = useState('')
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    useEffect(() => disptachGetMe(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [])

    const handleUpdatePassword = () => {
        setIsLoadingPassword(true)
        const passwordConfirm = password
        dispatchUpdatePassword(
            passwordCurrent,
            passwordConfirm,
            password,
            getme.id,
            (response) => { console.log(response); setPasswordSuccess(true); setIsLoadingPassword(false) },
            (error) => { console.log(error); setPasswordError(error.error); setIsLoadingPassword(false) }
        )
    }

    const handleUpdateInstruction = () => {
        setIsLoadingInstruction(true)

        dispatchUpdateInstruction(
            instruction || getme.instruction,
            getme.id,
            (response) => { console.log(response); setIsLoadingInstruction(false) },
            (error) => { console.log(error); setIsLoadingInstruction(false) }
        )
    }

    return (
        <div className='account'>
            {windowDimensions.width < 800 && (
                <div className='header_page_title_container'>
                    <img src={AccountIcon} className='header_page_title_icon_account' />
                    <p className='header_page_title'>{getme.restaurant}</p>
                </div>
            )}
            <div className='account_container'>
                <div className='account_section_content'>
                    <p className='account_update_password_title'>Password</p>
                    <div className='account_password_form'>
                        {isSecure === "password" ?
                            <IoIcons.IoEye size={20} className="account_icon_view" onClick={() => setIsSecure("text")} />
                            : <IoIcons.IoEyeOff size={20} className="account_icon_view" color="#dddfe2" onClick={() => setIsSecure("password")} />
                        }
                        <input
                            className="account_password_input"
                            type={isSecure}
                            placeholder="Current Password"
                            value={passwordCurrent}
                            onChange={(e) => setPasswordCurrent(e.target.value)}
                        />
                    </div>
                    <div className='account_password_form'>
                        {isSecureTwo === "password" ?
                            <IoIcons.IoEye size={20} className="account_icon_view" onClick={() => setIsSecureTwo("text")} />
                            : <IoIcons.IoEyeOff size={20} className="account_icon_view" color="#dddfe2" onClick={() => setIsSecureTwo("password")} />
                        }
                        <input
                            className="account_password_input"
                            type={isSecureTwo}
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <LoadingButton
                        title='Update Password'
                        color='blue'
                        onClick={handleUpdatePassword}
                        style={{ width: 280 }}
                        isLoading={loadingPassword}
                    />
                    {passwordError && (
                        <p className='login-error'>{passwordError}</p>
                    )}
                    {passwordSuccess && (
                        <p className='account_updated_success'>Password successfully updated</p>
                    )}
                </div>
                <div className='account_section_content'>
                    <p className='account_update_password_title'>Pick-Up Instruction</p>
                    <textarea
                        rows="5"
                        className='account_instruction_area'
                        maxlength="279"
                        onChange={(e) => setInstruction(e.target.value)}
                        placeholder={getme.instruction}
                    />
                    <LoadingButton
                        title='Update Instruction'
                        color='blue'
                        onClick={handleUpdateInstruction}
                        style={{ marginTop: 10, width: 280 }}
                        isLoading={loadingInstruction}
                    />
                </div>
                <div className='account_section_content'>
                    <p className='account_update_password_title'>Billing & Logout</p>
                    <Link to='/billing' className='account_billing_button'>Billing</Link>
                    <CustomButton
                        title='Logout'
                        color='green'
                        style={{ marginTop: 20, width: 280 }}
                        onClick={() => dispatchLogout()}
                    />
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
    dispatchUpdatePassword: (passwordCurrent, passwordConfirm, password, userId, onSuccess, onError) =>
        dispatch(updatePassword({ passwordCurrent, passwordConfirm, password, userId }, onSuccess, onError)),
    dispatchUpdateInstruction: (instruction, userId, onSuccess, onError) =>
        dispatch(updateUserProfile({ instruction }, userId, onSuccess, onError)),
    dispatchLogout: () => dispatch(logoutUser()),

})


const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(History)
