import React from 'react'
import { NumericFormat } from 'react-number-format'

import './styles.css'

const GroupForm = ({ item, type, cost, fmv, weight, setType, setCost, setFmv, setWeight }) => {
    return (
        <div className='group_form_container'>
            <p className='group_form_title'>Item {item}</p>
            <div className='group_form_input_container'>
                <p className='group_form_input_label'>Food type</p>
                <input
                    className="group_form_input"
                    type="text"
                    placeholder="Food type"
                    maxLength={25}
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                />
            </div>
            <div className='group_form_input_container'>
                <p className='group_form_input_label'>Cost</p>
                <NumericFormat
                    className='group_form_input'
                    onValueChange={(e) => setCost(Number(e.value))}
                    value={cost}
                    placeholder="Cost of goods"
                    prefix={'$'}
                    decimalScale={2}
                    decimalSeparator="."
                    valueIsNumericString={true}
                    type="text"
                    fixedDecimalScale={true}
                    thousandSeparator=","
                    maxLength={10}
                    allowNegative={false}
                />
            </div>
            <div className='group_form_input_container'>
                <p className='group_form_input_label'>FMV</p>
                <NumericFormat
                    className='group_form_input'
                    onValueChange={(e) => setFmv(Number(e.value))}
                    value={fmv}
                    placeholder="Fair market value"
                    prefix={'$'}
                    decimalScale={2}
                    decimalSeparator="."
                    valueIsNumericString={true}
                    type="text"
                    fixedDecimalScale={true}
                    thousandSeparator=","
                    maxLength={10}
                    allowNegative={false}
                />
            </div>
            <div className='group_form_input_container'>
                <p className='group_form_input_label'>Weight</p>
                <NumericFormat
                    className='group_form_input'
                    onValueChange={(e) => setWeight(Number(e.value))}
                    value={weight}
                    placeholder="Weight"
                    suffix={' lb'}
                    decimalScale={1}
                    decimalSeparator="."
                    valueIsNumericString={true}
                    type="text"
                    fixedDecimalScale={true}
                    thousandSeparator=","
                    maxLength={10}
                    allowNegative={false}
                />
            </div>
        </div>
    )
}

export default GroupForm