import React from 'react'
import { IoCloseSharp } from "react-icons/io5"
import { useNavigate } from "react-router-dom"

import './styles.css'

const ModalSubsribe = () => {
    const navigate = useNavigate()

    return (
        <div className='subscribe_modal'>
            <div className='subscribe_modal_container'>
                <IoCloseSharp size={35} className='subscribe_modal_icon_close' onClick={() => navigate('/')} />
                <p className='subscribe_modal_title'>Membership Expired </p>
                <p className='subscribe_modal_subtitle'>Please join or renew your membership to continue donating and access your history.</p>
                <button className='subscribe_modal_button' onClick={() =>  navigate('/billing')}>Billing</button>
            </div>
        </div>
    )
}

export default ModalSubsribe