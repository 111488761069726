import React, { useState, useEffect } from 'react'
import { useLocation } from "react-router-dom"
import Logo from '../../assets/images/logo.svg'
import { Ellipsis } from 'react-css-spinners'
import * as IoIcons from "react-icons/io5"
import { connect } from 'react-redux'
import { loginUser, registerUser, forgotPassword } from '../../redux/actions/auth'

import './styles.css'

const Auth = ({ dispatchLoginAction, dispatchSignupAction, dispathResetPassword }) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [serverError, setServerError] = useState('')
    const [loading, setLoading] = useState(false)
    const [isSecure, setIsSecure] = useState("password")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [address, setAdress] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [isSecureConfirm, setIsSecureConfirm] = useState("password")
    const [flow, setFlow] = useState('login')
    const [path, setPath] = useState("/")

    const location = useLocation()

    useEffect(() => {
        if(location.pathname === "/signup"){
            setFlow('create-account')
        }
    }, [location.pathname])

    const login = (event) => {
        event.preventDefault()
        setLoading(true)
        dispatchLoginAction(
            email,
            password,
            () => setLoading(false),
            (error) => { setServerError(error.error); setLoading(false) }
        )
    }

    const signup = (event) => {
        event.preventDefault()
        setLoading(true)

        dispatchSignupAction(
            name,
            email,
            phone,
            address,
            password,
            passwordConfirm,
            () => { setLoading(false); setFlow('login'); setServerError('') },
            (error) => { setServerError(error.error); setLoading(false) }
        )
    }

    const resetPassword = (event) => {
        event.preventDefault()

        setLoading(true)
        dispathResetPassword(
            email,
            () => { setLoading(false); setFlow('login') },
            (error) => { setServerError(error.error); setLoading(false) }
        )
    }

    return (
        <div className='auth'>
            <div className='auth_container'>
                <img src={Logo} alt="logo" className='auth_logo' />
                <h2 className='auth_title'>Earn money. Save the world.</h2>
                {flow === 'login' ?
                    <>
                        <div>
                            <div style={{ width: "100%", position: 'relative' }}>
                                <input
                                    className="login-input"
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </div>
                            <div style={{ width: "100%", position: 'relative' }}>
                                {isSecure === "password" ?
                                    <IoIcons.IoEye size={20} className="login-icons-view" onClick={() => setIsSecure("text")} />
                                    : <IoIcons.IoEyeOff size={20} className="login-icons-view" color="#dddfe2" onClick={() => setIsSecure("password")} />
                                }
                                <input
                                    className="login-input"
                                    type={isSecure}
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>
                            {serverError ? <p className="login-error">{serverError}</p> : null}
                            <p className="forgot_password_link"><span><button onClick={() => setFlow('forgot-password')} className="auth_link">Forgot password?</button></span></p>
                            <button className="login-button" disabled={loading ? true : false} onClick={login}>
                                {
                                    !loading ? 'Log In' : <span> <Ellipsis color="#FFF" size={38} style={{ marginTop: 3 }} /></span>
                                }
                            </button>
                        </div>
                        <p className="login-create-account">Don't have an account? <span><button onClick={() => { setFlow('create-account') }} className="auth_link">Sign Up</button></span></p>
                    </>
                    : flow === 'forgot-password' ?
                        <>
                            < form onSubmit={login} >
                                <div style={{ width: "100%", position: 'relative' }}>
                                    <input
                                        className="login-input"
                                        type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <button className="login-button" disabled={loading ? true : false} onClick={resetPassword}>
                                    {
                                        !loading ? 'Reset Password' : <span> <Ellipsis color="#FFF" size={38} style={{ marginTop: 3 }} /></span>
                                    }
                                </button>
                                {serverError ? <p className="login-error">{serverError}</p> : null}
                            </form >
                            <p className="login-create-account">Remember the password? <span><button onClick={() => setFlow('login')} className="auth_link">Log In</button></span></p>
                        </>

                        : flow === 'create-account' && (
                            <>
                                <form>
                                    <div style={{ width: "100%", position: 'relative' }}>
                                        <input
                                            className="login-input"
                                            type="text"
                                            placeholder="Restaurant name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>
                                    <div style={{ width: "100%", position: 'relative' }}>
                                        <input
                                            className="login-input"
                                            type="email"
                                            placeholder="Email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div style={{ width: "100%", position: 'relative' }}>
                                        <input
                                            className="login-input"
                                            type='phone'
                                            placeholder="Phone"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                        />
                                    </div>
                                    <div style={{ width: "100%", position: 'relative' }}>
                                        <input
                                            className="login-input"
                                            type="text"
                                            placeholder="Address"
                                            value={address}
                                            onChange={(e) => setAdress(e.target.value)}
                                        />
                                    </div>
                                    <div style={{ width: "100%", position: 'relative' }}>
                                        {isSecure === "password" ?
                                            <IoIcons.IoEye size={20} className="login-icons-view" onClick={() => setIsSecure("text")} />
                                            : <IoIcons.IoEyeOff size={20} className="login-icons-view" color="#dddfe2" onClick={() => setIsSecure("password")} />
                                        }
                                        <input
                                            className="login-input"
                                            type={isSecure}
                                            placeholder="Password"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </div>
                                    <div style={{ width: "100%", position: 'relative' }}>
                                        {isSecureConfirm === "password" ?
                                            <IoIcons.IoEye size={20} className="login-icons-view" onClick={() => setIsSecureConfirm("text")} />
                                            : <IoIcons.IoEyeOff size={20} className="login-icons-view" color="#dddfe2" onClick={() => setIsSecureConfirm("password")} />
                                        }
                                        <input
                                            className="login-input"
                                            type={isSecureConfirm}
                                            placeholder="Confirm passsword"
                                            value={passwordConfirm}
                                            onChange={(e) => setPasswordConfirm(e.target.value)}
                                        />
                                    </div>
                                    {serverError ? <p className="login-error">{serverError}</p> : null}
                                    <button className="login-button" disabled={loading ? true : false} onClick={signup}>
                                        {
                                            !loading ? 'Create Account' : <span> <Ellipsis color="#FFF" size={38} style={{ marginTop: 3 }} /></span>
                                        }
                                    </button>
                                </form>
                                <p className="login-create-account">Already have an account? <span><button onClick={() => setFlow('login')} className="auth_link">Log In</button></span></p>
                            </>
                        )}
                <p className="auth_contact_email">For more information, please email <span><a className="auth_contact_email_link" href="mailto:hello@eleplate.org">hello@eleplate.org</a></span></p>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    dispatchLoginAction: (email, password, onSuccess, onError) =>
        dispatch(loginUser({ email, password }, onSuccess, onError)),
    dispatchSignupAction: (restaurant, email, phone, address, password, passwordConfirm, onSuccess, onError) =>
        dispatch(registerUser({ restaurant, email, phone, address, password, passwordConfirm, }, onSuccess, onError)),
    dispathResetPassword: (email, onSuccess, onError) =>
        dispatch(forgotPassword({ email }, onSuccess, onError)),
})

export default connect(null, mapDispatchToProps)(Auth);
