import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from "react-router-dom"

import { getMe } from '../../redux/actions/user'

import AddItem from '../../components/AddItem'
import FoodItem from '../../components/FoodItem'
import ModalSubsribe from '../../components/ModalSubscribe'
import CreateFoodGroup from '../../components/CreateFoodGroup'
import FoodGroupItem from '../../components/FoodGroupItem'
import CustomButton from '../../components/CustomButton'

import BoxCheck from '../../assets/icons/box_check.svg'


import './styles.css'

const Donate = ({ getme, setAddScheduleItem, donationId, scheduledDonation, modalRef, modalNodeRef, isInModal }) => {
    const [addItem, setAddItem] = useState(false)
    const [addGroup, setAddGroup] = useState(false)
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    const containerStyle = isInModal ? 'donate_modal' : 'donate';
    const pageFixStyle = isInModal ? 'page_fix_modal' : 'page_fix_container';

    function getWindowDimensions() {
        // console.log('modalNodeRef in dimensions', modalNodeRef, modalNodeRef.current)
        const modalNode = document.querySelector('.unique-modal-class');
        if (modalNode) {
            const { clientWidth: width, clientHeight: height } = modalNode;
            return { width, height };
        }

        const { innerWidth: width, innerHeight: height } = window;
        return { width, height };
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        // console.log(windowDimensions)
        return () => window.removeEventListener('resize', handleResize);

    }, [modalRef]);

    // useEffect(() => {
    //     function handleResize() {
    //         setWindowDimensions(getWindowDimensions());
    //     }

    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, [])
    
    // console.log('modalRef', modalRef)
    // console.log(windowDimensions)
    // const taxDeductionFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(getme.bag.totals.totalTaxSavings)
    // const lbsFormat = new Intl.NumberFormat('us').format(getme.bag.totals.totalWeight)

    let scheduledDonationBag
    if (scheduledDonation) {
        scheduledDonationBag = getme.donations.find(donation => donation.id === donationId).bag
    }

    let taxDeductionFormat
    let itemCountFormat
    let lbsFormat
    if (scheduledDonation) {
        taxDeductionFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(scheduledDonationBag.totals.totalTaxSavings)
        itemCountFormat = scheduledDonationBag.totals.itemCount
        lbsFormat = new Intl.NumberFormat('us').format(scheduledDonationBag.totals.totalWeight)
    } else {
        taxDeductionFormat = new Intl.NumberFormat('us', { style: 'currency', currency: 'USD' }).format(getme.bag.totals.totalTaxSavings)
        itemCountFormat = getme.bag.totals.itemCount
        lbsFormat = new Intl.NumberFormat('us').format(getme.bag.totals.totalWeight)
    }

    return (
        <div className={containerStyle}>
            {(windowDimensions.width < 800 || isInModal)&& (
                <div className='header_page_title_container_donate'>
                    <img src={BoxCheck} className='header_page_title_icon_box' />
                    {scheduledDonation? <p className='header_page_title'>ADD TO DONATION</p> : <p className='header_page_title'>Donate</p>}
                </div>
            )}
            <div className={pageFixStyle}>
                {!getme.freeTrial && !getme.billing.subscription.active && !getme.billing.subscription.freeSubscription && (
                    <ModalSubsribe />
                )}
                {addGroup && <CreateFoodGroup setAddGroup={setAddGroup} />}
                <div className='donate_header'>
                    <div className='home_info_container'>
                        <div className='donate_info_box'>
                            <p className='donate_number_title'>{taxDeductionFormat}</p>
                            <p className='donate_text_subtitle'>Tax deductions</p>
                        </div>
                        <div className='donate_info_box'>
                            <p className='donate_number_title'>{itemCountFormat}</p>
                            <p className='donate_text_subtitle'>{getme.bag.totals.itemCount > 1 ? 'Items' : 'Item'}</p>
                        </div>
                        <div className='donate_info_box'>
                            <p className='donate_number_title'>{lbsFormat}<span style={{ marginLeft: 4 }}>lbs</span></p>
                            <p className='donate_text_subtitle'>Total weight</p>
                        </div>
                    </div>
                    {addItem &&
                        <AddItem setAddItem={setAddItem} />
                    }
                    <div className='donate_buttons_container'>
                        {scheduledDonation ? 
                            <Link className='donate_buttons_main' to='/schedule' onClick={() => setAddScheduleItem(false)} style={{ backgroundColor: '#EB896C'}}>Confirm</Link>

                            : 
                            <Link className='donate_buttons_main' to='/checkout' style={{ backgroundColor: '#EB896C'}}>Donate</Link>
                        }
                        <Link className='donate_buttons_main'onClick={() => setAddItem(true)} style={{ backgroundColor: '#31378B' }}>Add Item</Link>
                        <Link className='donate_buttons_main'onClick={() => setAddGroup(true)} style={{ backgroundColor: '#469EAA' }} >Add Group</Link>
                    </div>
                </div>
                <div className='donate_bag_item_section'>
                    <div className='donate_bag_item_container'>
                        {getme.foodItems.map((item) =>
                            <FoodItem
                                key={item.id}
                                itemId={item.id}
                                itemType={item.itemType}
                                cost={item.cost}
                                fmv={item.fmv}
                                taxSaving={Math.round(Number(item.taxSaving) * 100) / 100}
                                scheduledDonation={scheduledDonation}
                                donationId={donationId}
                            />)}

                        {getme.foodGroups.map((item) =>
                            <FoodGroupItem
                                key={item.id}
                                itemId={item.id}
                                cost={item.cost}
                                fmv={item.fmv}
                                category={item.category}
                                ownerId={item.ownerId}
                                items={item.items}
                                taxSaving={Math.round(Number(item.taxSaving) * 100) / 100}
                                scheduledDonation={scheduledDonation}
                                donationId={donationId}
                            />
                        )}

                        {(getme?.foodItems.length || getme?.foodGroups.length) < 1 ? (
                            <div className='no_results_item_container'>
                                <p className='no_results_item_title'>No food items/groups found</p>
                                <p className='no_results_item_subtitle'>Add new item or group above to start a donation</p>
                            </div>
                        ) : null}
                    </div>

                </div>
            </div>
        </div>
    )
}


const mapDispatchToProps = (dispatch) => ({
    disptachGetMe: () => dispatch(getMe()),
})

const mapStateToProps = (state) => ({
    getme: state.getme
})

export default connect(mapStateToProps, mapDispatchToProps)(Donate)
